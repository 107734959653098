import React, { useEffect, FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Content from "../../../layout/content/Content";
import { Card, Row, Col } from "reactstrap";
import {
    Block,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
} from "../../../components/Component";
import { Tabs, Descriptions, Button } from 'antd';
import type { TabsProps } from 'antd';

import Snapshot from './Snapshot';
import QuotationComparison from './QuotationComparison';
import BAFOAnalysis from './BAFOAnalysis';
import quotationApis from "../../../api/master/quotations";

const RFQFullView: FC = () => {
    const location = useLocation();
    const rfq = location.state?.rfq;
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState<string>('1'); // Track active tab
    const [countsData, setCountsData]: any = useState();
    const [supplierQuotations, setSupplierQuotations]: any = useState<any[]>([]);
    const [initialMaterials, setInitialMaterials] = useState<any[]>([]);

    console.log("RFQ",rfq)


    const handleTabChange = (key: string) => {
        setActiveTab(key);  // Update active tab state
    };

    useEffect(() => {
        // Additional initialization if necessary
    }, []);

    useEffect(() => {
        (async () => {
          const data = await getSupplierQuotations();
          console.log("111",data)
          setCountsData(data.counts)
          setSupplierQuotations(data.supplierHeaders);
          const data2 = data?.materials.map((item: any, index: any) => {
            return {
              ...item,
              key: index + 1,
            };
          });
          setInitialMaterials(data2);
        })()
      }, []);
    
      const getSupplierQuotations = async () => {
        try {
          const { response, status } = await quotationApis.quoteComparision(
            rfq.quotationId
          );
          console.log("setSupplierQuotations", response);
          if (status) {
            return response.data
          }
        } catch (error: any) {
        //   message.error(error.message);
        }
      };

    if (!rfq) {
        return <div>No RFQ data available</div>;
    }

    const items: TabsProps['items'] = [
        { key: '1', label: 'Snapshot', children: <Snapshot rfq={rfq} /> },
        { key: '2', label: 'Quotation Comparison', children: <QuotationComparison rfq={rfq} initialMaterialsData={initialMaterials} supplierQuotations={supplierQuotations} countsData={countsData}  /> },
        { key: '3', label: 'BAFO Analysis', children: <BAFOAnalysis rfq={rfq} activeTab={activeTab} /> },
    ];

    return (
        <>
            <Content>
                {/* <Card className="card-bordered"> */}
                    {/* <div className="card-inner"> */}
                        <div className="rfqoverview" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <BlockHead>
                                <BlockHeadContent>
                                    <BlockTitle tag="h5">Quotation Details</BlockTitle>
                                </BlockHeadContent>
                            </BlockHead>
                            <Descriptions column={5} style={{ flex: 1, marginLeft: ' 10px' }}>
                                <Descriptions.Item label="RFQ ID">{rfq.rfqId}</Descriptions.Item>
                                <Descriptions.Item label="Site Name">{rfq.siteName}</Descriptions.Item>
                                <Descriptions.Item label="Created Date">01-Aug-2024</Descriptions.Item>
                            </Descriptions>
                            <Button type="default" onClick={() => navigate(-1)} style={{ marginLeft: 'auto' }}>
                                <em className="icon ni ni-arrow-left" style={{ marginRight: '5px' }}></em>Back
                            </Button>
                        </div>
                        <Tabs
                            activeKey={activeTab}
                            onChange={handleTabChange} // Handle tab change
                            items={items}
                            tabBarStyle={{
                                color: '#2263b3',
                                fontSize: '20px',
                            }}
                            tabBarGutter={32}
                            renderTabBar={(props, DefaultTabBar) => (
                                <DefaultTabBar {...props} style={{ marginBottom: '0' }} />
                            )}
                        />
                    {/* </div> */}
                {/* </Card> */}
            </Content>

        </>
    );
};

export default RFQFullView;
