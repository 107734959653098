// import axiosInstance from '../axiosInstance';

// import { LoginCredentials, AuthResponse } from '../types/auth/authTypes';

// export const login = async (credentials: LoginCredentials): Promise<any> => {
//   try {
//     const response = await axiosInstance.post<AuthResponse>('/auth/login', credentials);
//     return response.data;
//   } catch (error:any) {
//     throw new Error('Login failed: ' + error.message);
//   }
// };

// export const setPassword = async (credentials:any,accessToken:any): Promise<any> => {
//   try {
//     const headers = accessToken ? { 'Authorization': `${accessToken}` } : {};
//     const response = await axiosInstance.put<AuthResponse>('/users/editPassword', credentials,{ headers });
//     return response.data;
//   } catch (error:any) {
//     throw new Error('Login failed: ' + error.message);
//   }
// };

// export const reSetPassword = async (data:any): Promise<any> => {
//   try {
//     const response = await axiosInstance.post<AuthResponse>('/users/sendResetEmailToRegisteredUser', data);
//     return response.data;
//   } catch (error:any) {
//     throw new Error('Reset failed: ' + error.message);
//   }
// };

// export const logout = async () => {
//   try {
//     const response = await axiosInstance.post<AuthResponse>('/auth/logout',);
//     return response.data;
//   } catch (error:any) {
//     throw new Error('Logout failed: ' + error.message);
//   }
// };
 

import makeApiCall from '../master/axiosIndex';
import { LoginCredentials} from '../types/auth/authTypes';
const authService = {
  login:(credentials: LoginCredentials): Promise<any> => {
    return makeApiCall({
      url: '/auth/login',
      method: 'post',
      data: credentials
    });
  },
  setPassword:(credentials:any,accessToken:any): Promise<any> => {
    return makeApiCall({
      url: '/users/editPassword',
      method: 'put',
      data: credentials
    });
  },
  reSetPassword:(data:any): Promise<any> => {
    return makeApiCall({
      url: '/users/sendResetEmailToRegisteredUser',
      method: 'post',
      data
    });
  },
  logout:(): Promise<any> => {
    return makeApiCall({
      url: '/auth/logout',
      method: 'post'
    });
  },
  refreshToken:(): Promise<any> => {
    return makeApiCall({
      url: '/auth/refresh',
      method: 'post',
    });
  }
};

export default authService;