import React, { useState, useEffect } from 'react';
import { Drawer, Tabs, Select, Empty, Card, Table, Tooltip } from 'antd';
import * as echarts from 'echarts';
import { PieChartOutlined, TableOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { formatCurrency2, formatCurrency } from '../../../utils/Utils';

const { TabPane } = Tabs;
const { Option } = Select;
const currency = 'INR';
const categories = [
    { categoryID: 'cat001', categoryName: 'Steel' },
    { categoryID: 'cat002', categoryName: 'Cement' },
    { categoryID: 'cat003', categoryName: 'Electricals' },
    { categoryID: 'cat004', categoryName: 'HVAC' },
];

const budgetOverrunData = {
    siteID: 'SITE001',
    siteName: 'Site A',
    data: [
        {
            materialID: 'mat001',
            materialName: '10 MM TMT Bar',
            categoryID: 'cat001',
            categoryName: 'Steel',
            budgetAllocated: 2400000,
            spent: 3000000, // Overrun
        },
        {
            materialID: 'mat002',
            materialName: '12 MM TMT Bar',
            categoryID: 'cat001',
            categoryName: 'Steel',
            budgetAllocated: 3000000,
            spent: 3200000, // Overrun
        },
        {
            materialID: 'mat003',
            materialName: '16 MM TMT Bar',
            categoryID: 'cat001',
            categoryName: 'Steel',
            budgetAllocated: 2500000,
            spent: 2900000, // Overrun
        },
        {
            materialID: 'mat004',
            materialName: '20 MM TMT Bar',
            categoryID: 'cat001',
            categoryName: 'Steel',
            budgetAllocated: 2800000,
            spent: 2900000, // Overrun
        },
        {
            materialID: 'mat005',
            materialName: '25 MM TMT Bar',
            categoryID: 'cat001',
            categoryName: 'Steel',
            budgetAllocated: 3500000,
            spent: 4000000, // Overrun
        },
        {
            materialID: 'mat006',
            materialName: 'OPC 43 Grade Cement',
            categoryID: 'cat002',
            categoryName: 'Cement',
            budgetAllocated: 1500000,
            spent: 1800000, // Overrun
        },
        {
            materialID: 'mat007',
            materialName: 'OPC 53 Grade Cement',
            categoryID: 'cat002',
            categoryName: 'Cement',
            budgetAllocated: 2000000,
            spent: 2100000, // Overrun
        },
    ],
};

const MaterialWiseBudgetOverrunDrawer: React.FC<any> = ({
    visible,
    onClose,
    materialWiseBudgetOverrunbyCategoryData
}) => {


    const [plotCategoryID, setPlotCategoryID] = useState<string>(categories[0].categoryID);
    const [tableCategoryID, setTableCategoryID] = useState<string>(categories[0].categoryID);
    const [plotData, setPlotData] = useState<any[]>([]);
    const [tableData, setTableData] = useState<any[]>([]);
    const [maxOverrun, setMaxOverrun] = useState<any>(null);
    const [minOverrun, setMinOverrun] = useState<any>(null);

    useEffect(() => {
        console.log("filtereddaaaaa")
        const filteredData = materialWiseBudgetOverrunbyCategoryData?.materialWiseBudgetOverrunbyCategoryData?.materialOverRunList;
        setPlotData(filteredData);

        if (filteredData?.length > 0) {
            const maxOverrunItem = filteredData.reduce((prev:any, curr:any) =>
                prev.spent - prev.budgetAllocated > curr.spent - curr.budgetAllocated ? prev : curr
            );
            const minOverrunItem = filteredData.reduce((prev:any, curr:any) =>
                prev.spent - prev.budgetAllocated < curr.spent - curr.budgetAllocated ? prev : curr
            );
            console.log("filteredData", filteredData,maxOverrunItem, minOverrunItem);
            // const maxOverrunItem = materialWiseBudgetOverrunbyCategoryData?.materialWiseBudgetOverrunbyCategoryData?.maxOverRunList;
            // const minOverrunItem = materialWiseBudgetOverrunbyCategoryData?.materialWiseBudgetOverrunbyCategoryData?.minOverRunList;

            setMaxOverrun(maxOverrunItem);
            setMinOverrun(minOverrunItem);

            setTimeout(() => initializeChart(filteredData), 0); // Immediate chart initialization after data change
        } else {
            setMaxOverrun(null);
            setMinOverrun(null);
            clearChart();
        }
    }, [plotCategoryID]);

    useEffect(() => {
        const filteredData = materialWiseBudgetOverrunbyCategoryData?.materialWiseBudgetOverrunbyCategoryData?.materialOverRunList;
        setTableData(filteredData);
    }, [tableCategoryID]);

    useEffect(() => {
        if (visible) {
            const initialData = materialWiseBudgetOverrunbyCategoryData?.materialWiseBudgetOverrunbyCategoryData?.materialOverRunList
            setPlotData(initialData);
            if (initialData?.length > 0) {
                setTimeout(() => initializeChart(initialData), 0); // Immediate chart initialization after drawer opens
            }
        }
    }, [visible]); // Run when the drawer becomes visible

    const initializeChart = (data: any[]) => {
        const chartDom = document.getElementById('donut-chart') as HTMLElement;
        if (chartDom) {
            const myChart = echarts.init(chartDom);

            const chartData = data.map((item) => ({
                name: item.materialName,
                value: item.spent,
            }));

            const option = {
                tooltip: {
                    trigger: 'item',
                    formatter: (params: any) => {
                        const value = formatCurrency(params.value, 'INR');
                        return `${params.seriesName} <br/>${params.name}: ${value} (${params.percent}%)`;
                    },
                },
                legend: {
                    orient: 'horizontal',
                    bottom: 0,
                    left: 'center',
                },
                series: [
                    {
                        name: 'Budget Overrun',
                        type: 'pie',
                        radius: ['50%', '70%'],
                        roseType: 'radius',
                        avoidLabelOverlap: false,
                        label: {
                            show: true,
                            formatter: '{b|{b}}\n{c|{c} ({d}%)}',
                            rich: {
                                b: {
                                    fontSize: 14,
                                    color: '#333',
                                    lineHeight: 24,
                                },
                                c: {
                                    fontSize: 12,
                                    color: '#999',
                                    lineHeight: 20,
                                },
                            },
                        },
                        labelLine: {
                            show: true,
                            length: 15,
                            length2: 10,
                            smooth: true,
                        },
                        itemStyle: {
                            borderColor: '#E0E4FF',  // Border color
                            borderWidth: 1,           // Border width
                            shadowBlur: 10,           // Shadow blur
                            shadowOffsetX: 0,         // Shadow X offset
                            shadowOffsetY: 5,         // Shadow Y offset
                            shadowColor: 'rgba(255, 255, 255, 0.2)', // Shadow color
                            borderRadius: 5
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '18',
                                fontWeight: 'bold',
                            },
                        },
                        // labelLine: {
                        //     show: false,
                        // },
                        data: chartData,
                    },
                ],
            };

            myChart.setOption(option);

            return () => {
                myChart.dispose();
            };
        }
    };

    const clearChart = () => {
        const chartDom = document.getElementById('donut-chart') as HTMLElement;
        if (chartDom) {
            const myChart = echarts.init(chartDom);
            myChart.clear();
            myChart.setOption({
                graphic: {
                    type: 'text',
                    left: 'center',
                    top: 'center',
                    style: {
                        text: 'No Data Available',
                        fontSize: 20,
                        fill: '#ccc',
                    },
                },
            });
        }
    };

    return (
        <Drawer
            title="Material Wise Budget Overrun by Category"
            placement="right"
            onClose={onClose}
            open={visible}
            width={'75%'}
        >
            <Tabs defaultActiveKey="plot">
                <TabPane
                    tab={
                        <span>
                            <PieChartOutlined /> &nbsp;
                            Plot
                        </span>
                    }
                    key="plot"
                >
                    {/* <div style={{ marginBottom: 16 }}>
                        <label style={{ display: 'block', marginBottom: 8 }}>Filter by Category:</label>
                        <Select
                            placeholder="Select Category"
                            style={{ width: 200 }}
                            value={plotCategoryID}
                            onChange={(value) => setPlotCategoryID(value)}
                        >
                            {categories.map((category) => (
                                <Option key={category.categoryID} value={category.categoryID}>
                                    {category.categoryName}
                                </Option>
                            ))}
                        </Select>
                    </div> */}
                    {plotData?.length > 0 ? (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div id="donut-chart" style={{ width: '60%', height: '400px' }} />
                            <div id="max-min-cards" style={{ width: '35%' }}>
                                {maxOverrun && (
                                    <Card title="Max Overrun" style={{ marginBottom: 16 }}>
                                        <p><strong>{maxOverrun.materialName}</strong></p>
                                        <p>Overrun: {formatCurrency2(maxOverrun.spent - maxOverrun.budgetAllocated, 'INR')} ({(((maxOverrun.spent - maxOverrun.budgetAllocated) / maxOverrun.budgetAllocated) * 100).toFixed(2)}%)</p>
                                    </Card>
                                )}
                                {minOverrun && (
                                    <Card title="Min Overrun">
                                        <p><strong>{minOverrun.materialName}</strong></p>
                                        <p>Overrun: {formatCurrency2(minOverrun.spent - minOverrun.budgetAllocated, 'INR')} ({(((minOverrun.spent - minOverrun.budgetAllocated) / minOverrun.budgetAllocated) * 100).toFixed(2)}%)</p>
                                    </Card>
                                )}
                            </div>
                        </div>
                    ) : (
                        <Empty description="No budget details found for this category" />
                    )}
                </TabPane>
                <TabPane
                    tab={
                        <span>
                            <TableOutlined /> &nbsp;
                            Table View
                        </span>
                    }
                    key="table"
                >
                    {/* <div style={{ marginBottom: 16 }}>
                        <label style={{ display: 'block', marginBottom: 8 }}>Filter by Category:</label>
                        <Select
                            placeholder="Select Category"
                            style={{ width: 200 }}
                            value={tableCategoryID}
                            onChange={(value) => setTableCategoryID(value)}
                        >
                            {categories.map((category) => (
                                <Option key={category.categoryID} value={category.categoryID}>
                                    {category.categoryName}
                                </Option>
                            ))}
                        </Select>
                    </div> */}
                    <Table
                        columns={[
                            {
                                title: 'Material ID',
                                dataIndex: 'materialID',
                                key: 'materialID',
                            },
                            {
                                title: 'Material Name',
                                dataIndex: 'materialName',
                                key: 'materialName',
                            },
                            {
                                title: 'Budget Allocated',
                                dataIndex: 'budgetAllocated',
                                key: 'budgetAllocated',
                                render: (value: number) => (
                                    <>
                                        {formatCurrency2(value, currency)}{' '}
                                        <Tooltip title={formatCurrency(value, currency)}>
                                            <InfoCircleOutlined style={{ fontSize: '12px', marginLeft: 4 }} />
                                        </Tooltip>
                                    </>
                                ),
                            },
                            {
                                title: 'Spent',
                                dataIndex: 'spent',
                                key: 'spent',
                                render: (value: number) => (
                                    <>
                                        {formatCurrency2(value, currency)}{' '}
                                        <Tooltip title={formatCurrency(value, currency)}>
                                            <InfoCircleOutlined style={{ fontSize: '12px', marginLeft: 4 }} />
                                        </Tooltip>
                                    </>
                                ),
                            },
                            {
                                title: 'Overrun',
                                key: 'overrun',
                                render: (_: any, record: any) => {
                                    const overrun = record.spent - record.budgetAllocated;
                                    const percentage = ((overrun / record.budgetAllocated) * 100).toFixed(2);
                                    return (
                                        <>
                                            {formatCurrency2(overrun, currency)} ({percentage}%){' '}
                                            <Tooltip title={formatCurrency(overrun, currency)}>
                                                <InfoCircleOutlined style={{ fontSize: '12px', marginLeft: 4 }} />
                                            </Tooltip>
                                        </>
                                    );
                                },
                            },
                        ]}
                        dataSource={tableData}
                        pagination={{ pageSize: 10 }}
                        rowKey="materialID"
                        rowClassName={(record, index) => (index % 2 === 0 ? '' : 'striped-row')}
                    />
                </TabPane>
            </Tabs>
        </Drawer>
    );
};

export default MaterialWiseBudgetOverrunDrawer;
