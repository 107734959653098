import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import { Card } from "reactstrap";
import Head from "../../../layout/head/Head";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  // LoginLogTable,
  Button,
} from "../../../components/Component";

import UserProfileAside from "./UserProfileAside";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Empty } from "antd";

const UserProfileActivityPage = () => {
  const { data: currentUser, isLoading } = useSelector(
    (state) => state.currentUser
  );
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    // document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
    //   updateSm(false);
    // });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <UserProfileAside updateSm={updateSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => updateSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">Login Activity</BlockTitle>
                    <BlockDes>
                      <p>
                        Here is your last {currentUser?.loginActivity?.length}{" "}
                        login activities log.{" "}
                        <span className="text-soft">
                          <Icon name="info" />
                        </span>
                      </p>
                    </BlockDes>
                  </BlockHeadContent>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${
                        sm ? "active" : ""
                      }`}
                      onClick={() => updateSm(!sm)}
                    >
                      <Icon name="menu-alt-r"></Icon>
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <LoginLogTable loginData={currentUser?.loginActivity} />
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default UserProfileActivityPage;

const LoginLogTable = ({ loginData }) => {
  return loginData?.length > 0 ? (
    <table className="table table-ulogs">
      <thead className="table-light">
        <tr>
          <th className="tb-col-os">
            <span className="overline-title">
              Browser <span className="d-sm-none">/ IP</span>
            </span>
          </th>
          <th className="tb-col-ip">
            <span className="overline-title">IP</span>
          </th>
          <th className="tb-col-time">
            <span className="overline-title">Date</span>
          </th>
          <th className="tb-col-time">
            <span className="overline-title">Time</span>
          </th>

          <th className="tb-col-action">
            <span className="overline-title">&nbsp;</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {loginData?.map((item, idx) => {
          return (
            <tr key={idx}>
              <td className="tb-col-os">{item?.deviceInfo}</td>
              <td className="tb-col-ip">
                <span className="sub-text">{item.ipAddress}</span>
              </td>
              <td className="tb-col-time">
                <span className="sub-text">
                  {dayjs(item?.loginDateTime)?.format("DD-MMM-YYYY")}{" "}
                </span>
              </td>
              <td className="tb-col-time">
                <span className="sub-text">
                  {dayjs(item?.loginDateTime)?.format("hh:mm A")}
                </span>
              </td>
              <td className="tb-col-action">
                {item.action === true && (
                  <a
                    href="#delete"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                    className="link-cross me-sm-n1"
                  >
                    <Icon name="cross"></Icon>
                  </a>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "200px",
      }}
    >
      <Empty description="No Login Activity" />
    </div>
  );
};
