import React, { FC, useState, useEffect } from "react";
import { Card } from "reactstrap";
import { TrophyOutlined } from "@ant-design/icons";
import { Tag, Pagination, Button, Flex, Spin } from "antd";
import { getUserLabel, colors } from "../../../utils/Utils";
import UserLabel from "../../../utils/UserLabel";
import BafoDrawerComponent from "./bafo/BAFODrawer";
import { EyeOutlined } from "@ant-design/icons";
import quotationApis from "../../../api/master/quotations";
import dayjs from "dayjs";
interface BAFOAnalysisProps {
  rfq?: any; // Make rfq optional
  activeTab?: any
}

const BAFOAnalysis: FC<BAFOAnalysisProps> = ({ rfq, activeTab }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items to show per page

  const getdynamicRankings = async () => {
    setIsLoading(true)
    try {
      const { response, status } = await quotationApis.dynamicRankings(rfq.quotationId);
      if (status) {
        // Simulate fetched data
        const simulatedData = response.data;

        setSuppliers(simulatedData);
        setLoading(false);
        setIsLoading(false);
      }
    } catch (error) {

    }
  };

  useEffect(() => {
    // Simulate loading delay
    if (activeTab === '3') {
      getdynamicRankings();  // Trigger getdynamicRankings when activeTab is 3
    }

  }, [activeTab]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // Pagination logic
  const paginatedItems = suppliers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerData, setDrawerData] = useState({
    supplierQuoteId: "",
    supplierID: "",
    supplierName: "",
    quotationId: "",
    siteName:""
  });

  const handleViewClick = (
    supplierQuoteId: any,
    supplierID: any,
    supplierName: any,
    quotationId: any,
    siteName:any
  ) => {
    setDrawerData({ supplierQuoteId, supplierID, supplierName, quotationId,siteName });
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };
  return (
    <div>
      <Card
        className="card-bordered"
        style={{ minHeight: "410px", overflowY: "auto" }}
      >
        <div className="card-inner">
          {isLoading == true ? (
            <>
              <Flex align="center" gap="middle" className="pageLoader">
                <Spin size="large" className="m-auto" />
              </Flex>
            </>
          ) : paginatedItems.length > 0 ? (
            paginatedItems.map((supplier, index) => (
              <div className="bordered_box" key={supplier.supplierID}>
                <div
                  className="boxItems"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <div
                    className="item"
                    style={{
                      ...itemStyle,
                      flexBasis: "10%",
                      flexGrow: 1,
                      minWidth: "200px",
                    }}
                  >
                    <UserLabel
                      supplierName={supplier.supplierName}
                      color={colors[index % colors?.length]}
                      text={supplier.supplierName}
                    />
                    <div style={{ display: "flex", marginTop: "8px" }}>
                      <Tag
                        color={
                          supplier.currentStatus === "AWARDED" || "SHORTLIST"
                            ? "success"
                            : "processing"
                        }
                        style={{ textAlign: "center" }}
                      >
                        {supplier.currentStatus}
                      </Tag>
                      {supplier.currentStatus !== "QUOTED"  && (
                        <div
                          onClick={() =>
                            handleViewClick(
                              supplier.supplierQuoteId,
                              supplier.supplierId,
                              supplier.supplierName,
                              rfq.quotationId,
                              rfq.siteName
                            )
                          }
                          style={{
                            marginLeft: "10px",
                            borderRadius: "10px",
                            padding: "2px 10px",
                            cursor: "pointer",
                            backgroundColor: "#1890ff", // primary color
                            color: "#fff",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 12,
                          }}
                        >
                          <EyeOutlined /> &nbsp; View Details
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="item" style={itemStyle}>
                    <TrophyOutlined size={50} style={{ fontSize: 30 }} />
                    <span style={{ display: "block", textAlign: "center" }}>
                      Rank {supplier.rank}
                    </span>
                  </div>
                  <div className="item" style={itemStyle}>
                    Material Coverage
                    <span style={spanStyle}>{supplier.materialScore}</span>
                  </div>
                  <div className="item" style={itemStyle}>
                    Volume Coverage
                    <span style={spanStyle}>{supplier.volumeScore?.toFixed(2)}</span>
                  </div>
                  <div className="item" style={itemStyle}>
                    Credit Coverage
                    <span style={spanStyle}>{supplier.creditScore?.toFixed(2)}</span>
                  </div>
                  <div className="item" style={itemStyle}>
                    On-time Delivery
                    <span style={spanStyle}>{supplier.onTimeDeliveryScore}</span>
                  </div>
                  <div className="item" style={itemStyle}>
                    Quotation Value
                    <span style={spanStyle}>{supplier.quotationScore?.toFixed(2)}</span>
                  </div>
                  <div className="item" style={itemStyle}>
                    Score
                    <span
                      style={{
                        fontSize: 20,
                        display: "block",
                        textAlign: "center",
                      }}
                    >
                      <strong>{supplier?.score?.toFixed(2)}</strong>
                    </span>
                  </div>
                  <div className="item" style={itemStyle}>
                    Quotation Validity Date
                    <span style={spanStyle}>{supplier.validityDate ? dayjs(supplier.validityDate).format("DD-MM-YYYY") : "N/A"}</span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>No suppliers available</div>
          )}
          {/* Pagination Component */}
          {!loading && (
            <Pagination
              current={currentPage}
              pageSize={itemsPerPage}
              total={suppliers.length}
              onChange={handlePageChange}
              style={{ marginTop: "16px", textAlign: "center" }}
            />
          )}
        </div>
      </Card>
      {drawerVisible &&
        <BafoDrawerComponent
          visible={drawerVisible}
          onClose={closeDrawer}
          data={drawerData}
        />
      }
    </div>
  );
};

// CSS Styles
const itemStyle: React.CSSProperties = {
  flexBasis: "10%", // Adjust the width as needed
  padding: "5px",
  boxSizing: "border-box",
  textAlign: "center",
};

const spanStyle: React.CSSProperties = {
  display: "block",
  textAlign: "center",
};

export default BAFOAnalysis;
