import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import { Card } from "reactstrap";
import Head from "../../../layout/head/Head";
import DatePicker from "react-datepicker";
import { Modal, ModalBody } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  Button,
  RSelect,
} from "../../../components/Component";
import { countryOptions, userData } from "./UserData";
import { getDateStructured } from "../../../utils/Utils";
import UserProfileAside from "./UserProfileAside";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchCurrentUser } from "../../../store/slices/misc/currentUser";
import { FormProvider, useForm } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import usersApis from "../../../api/master/users";

const UserProfileRegularPage = () => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = methods;
  const { data: currentUser, isLoading } = useSelector(
    (state) => state.currentUser
  );
  const dispatch = useDispatch();
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [modal, setModal] = useState(false);
  useEffect(() => {
    if (currentUser) {
      setValue("firstName", currentUser?.firstName);
      setValue("lastName", currentUser?.lastName);
      setValue("phoneNumber", currentUser?.phoneNumber);
      setValue("email", currentUser?.email);

      setUserInfo({
        firstName: currentUser?.firstName,
        lastName: currentUser?.lastName,
        phoneNumber: currentUser?.phoneNumber,
        email: currentUser?.email,
        roleName: currentUser?.roleName,
      });
    } else {
      dispatch(fetchCurrentUser());
    }
  }, [currentUser]);

  const submitUpdateForm = async (_data) => {
    setDisableSubmit(true);
    try {
      const res = await usersApis.editUser({
        id: currentUser?.id,
        ..._data,
      });
      if (res?.status) {
        dispatch(fetchCurrentUser());
      }
    } catch (error) {
      console.log(error);
    }
    setModal(false);
    setDisableSubmit(false);
  };

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <UserProfileAside updateSm={updateSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => updateSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">Personal Information</BlockTitle>
                    <BlockDes>
                      <p>
                        Basic info, like your name and address, that you use on
                        Nio Platform.
                      </p>
                    </BlockDes>
                  </BlockHeadContent>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${
                        sm ? "active" : ""
                      }`}
                      onClick={() => updateSm(!sm)}
                    >
                      <Icon name="menu-alt-r"></Icon>
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>

              <Block>
                <div className="nk-data data-list">
                  <div className="data-head">
                    <h6 className="overline-title">Basics</h6>
                  </div>
                  <div className="data-item" onClick={() => setModal(true)}>
                    <div className="data-col">
                      <span className="data-label">First Name</span>
                      <span className="data-value">{userInfo?.firstName||"---"}</span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more">
                        <Icon name="forward-ios"></Icon>
                      </span>
                    </div>
                  </div>
                  <div className="data-item" onClick={() => setModal(true)}>
                    <div className="data-col">
                      <span className="data-label">Last Name</span>
                      <span className="data-value">{userInfo?.lastName||"---"}</span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more">
                        <Icon name="forward-ios"></Icon>
                      </span>
                    </div>
                  </div>
                  <div className="data-item" onClick={() => setModal(true)}>
                    <div className="data-col">
                      <span className="data-label">Role</span>
                      <span className="data-value">{userInfo?.roleName||"---"}</span>
                    </div>
                    <div className="data-col data-col-end">
                    </div>
                  </div>
                  <div className="data-item">
                    <div className="data-col">
                      <span className="data-label">Email</span>
                      <span className="data-value">{userInfo?.email||"---"}</span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more disable">
                        <Icon name="lock-alt"></Icon>
                      </span>
                    </div>
                  </div>
                  <div className="data-item" onClick={() => setModal(true)}>
                    <div className="data-col">
                      <span className="data-label">Phone Number</span>
                      <span className="data-value text-soft">
                        {userInfo?.phoneNumber||"---"}
                      </span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more">
                        <Icon name="forward-ios"></Icon>
                      </span>
                    </div>
                  </div>
                  
                  {/* <div className="data-item" onClick={() => setModal(true)}>
                    <div className="data-col">
                      <span className="data-label">Date of Birth</span>
                      <span className="data-value">{userInfo.dob}</span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more">
                        <Icon name="forward-ios"></Icon>
                      </span>
                    </div>
                  </div>
                  <div className="data-item" onClick={() => setModal(true)}>
                    <div className="data-col">
                      <span className="data-label">Address</span>
                      <span className="data-value">
                        {userInfo.address},
                        <br />
                        {userInfo.state}, {userInfo.country}
                      </span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more">
                        <Icon name="forward-ios"></Icon>
                      </span>
                    </div>
                  </div> */}
                </div>
                {/* <div className="nk-data data-list">
                  <div className="data-head">
                    <h6 className="overline-title">Preferences</h6>
                  </div>
                  <div className="data-item">
                    <div className="data-col">
                      <span className="data-label">Language</span>
                      <span className="data-value">English (United State)</span>
                    </div>
                    <div className="data-col data-col-end">
                      <a
                        href="#language"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                        className="link link-primary"
                      >
                        Change Language
                      </a>
                    </div>
                  </div>
                  <div className="data-item">
                    <div className="data-col">
                      <span className="data-label">Date Format</span>
                      <span className="data-value">MM/DD/YYYY</span>
                    </div>
                    <div className="data-col data-col-end">
                      <a
                        href="#link"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                        className="link link-primary"
                      >
                        Change
                      </a>
                    </div>
                  </div>
                  <div className="data-item">
                    <div className="data-col">
                      <span className="data-label">Timezone</span>
                      <span className="data-value">Bangladesh (GMT +6)</span>
                    </div>
                    <div className="data-col data-col-end">
                      <a
                        href="#link"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                        className="link link-primary"
                      >
                        Change
                      </a>
                    </div>
                  </div>
                </div> */}
              </Block>

              <Modal
                isOpen={modal}
                className="modal-dialog-centered"
                size="lg"
                toggle={() => setModal(false)}
              >
                <a
                  href="#dropdownitem"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModal(false);
                  }}
                  className="close"
                >
                  <Icon name="cross-sm"></Icon>
                </a>
                <ModalBody>
                  <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(submitUpdateForm)}>
                      <h5 className="title">Update Profile</h5>
                      <div className="p-2">
                        <Row className="gy-4">
                          <div className="col-md-4">
                            <FormInput
                              name="firstName"
                              label="First Name"
                              type="text"
                              maxLength={20}
                              placeholder="Enter First Name"
                              required={true}
                            />
                          </div>
                          <div className="col-md-4">
                            <FormInput
                              name="lastName"
                              label="Last Name"
                              type="text"
                              maxLength={20}
                              placeholder="Enter Last Name"
                            />
                          </div>
                          <div className="col-md-4">
                            <FormInput
                              name="phoneNumber"
                              label="Phone Number"
                              type="text"
                              required={true}
                              placeholder="Enter Phone Number"
                              pattern={/^(?:(\+91)?[\-\s]?)?[6-9]\d{9}$/}
                            />
                          </div>
                          {/* <div className="col-md-4">
                            <FormInput
                              type="text"
                              name="email"
                              label="Email"
                              placeholder="Enter Contact Mail"
                              pattern={
                                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/
                              }
                            />
                          </div> */}

                          <Col size="12">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                              <li>
                                <Button color="primary" size="md" type="submit">
                                  Update
                                </Button>
                              </li>
                              <li>
                                <a
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setModal(false);
                                  }}
                                  className="link link-light"
                                >
                                  Cancel
                                </a>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </div>
                    </form>
                  </FormProvider>
                </ModalBody>
              </Modal>
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default UserProfileRegularPage;
