import React, { useState, useEffect, FC, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Content from "../../layout/content/Content";
import {
  Card,
  Badge,
  Row,
  Collapse,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  TooltipComponent,
} from "../../components/Component";
import BudgetAside from "./BudgetAside";
import FormatDate from "../masters/DateFormater";
import supplierApis from "../../api/master/supplier";
import costCenterApis from "../../api/master/costCenter";
import sites from "../../api/master/sites";
import GeneralLedgerApis from "../../api/master/generalLedger";
import departments from "../../api/master/departments";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  PaperClipOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Form,
  TableColumnsType,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Select,
  Divider,
  Space,
  Timeline,
  Collapse as AntCollapse,
  Button as AntButton,
  Descriptions,
  List,
  Modal as AntModal,
  Empty,
  Flex,
  Spin,
  DatePicker,
} from "antd";
import { nanoid } from "nanoid";
import ReactQuill from "react-quill";
import FormSelect from "../form-components/SelectComponent";
import FormInput from "../form-components/InputComponent";
import FormDatePicker from "../form-components/DatePicker";
import FormTextArea from "../form-components/TextAreaComponent";
import misc from "../../api/misc/dropDowns";
import deliveryAddressApis from "../../api/master/deliveryAddress";
import adminOrgApis from "../../api/master/adminOrg";
// import EditPoModal from "./EditPoModal";
import purchaseOrderApis from "../../api/master/PurchaseOrder";
import { InfoCircleOutlined } from "@ant-design/icons";
import type { StepsProps } from "antd";
import { Popover, Steps } from "antd";
import Swal from "sweetalert2";
import moment from "moment";
// import MaterialModal from "./EditPOMaterialModal";
import CropperComponent from "../components/CropperComponent";
// import SignatureModal from "./SignatureModal";
import rolesApi from "../../api/master/roles";
import { error } from "console";
import dayjs from "dayjs";
import budgetApis from "../../api/master/budget";
import MaterialModal from "./EditBudgetMaterialModal";
import Dragger from "antd/es/upload/Dragger";
import Misc from "../masters/Misc";
// import QuotationApprovalDrawer from "./QuotationApprovalDrawer";
// import ChangeToBuyerModal from "./ChangeToBuyerModal";
// import ChangeToBuyerDrawer from "./ChangeToBuyerModal";
import { UploadFile } from "antd/es/upload/interface";
import axios from "axios";
const { Panel } = AntCollapse;
const { FormatCurrency, beforeUpload } = Misc;

interface LineItem {
  lineId: number;
  budgetLineName: string;
  lineDescription: string;
  budgetValue: string;
  startDate: string;
  endDate: string;
  bom: string;
  allocation: string;
}

interface EventChain {
  id: number;
  orderNumber: number;
  orgId: number;
  eventType: string;
  eventName: string;
  error: string | null;
  eventDate: string;
  createdBy: number;
}

interface Item {
  orderNumber: number;
  referenceId: string;
  mode: string;
  version: number;
  orderDate: string;
  needByDateType: string;
  needByDate: string | null;
  orgId: number;
  siteId: number;
  supplierId: number;
  costCenterId: number;
  description: string;
  ledgerId: number;
  departmentId: number;
  budgetId: number;
  paymentTerms: string;
  advancePercentage: number;
  creditDays: number;
  paymentTermsDescription: string | null;
  inwardRequired: number;
  autoRelease: number;
  autoPoDoc: number;
  billingAddressId: number;
  deliveryAddressType: string;
  deliveryAddressId: string | null;
  currencyId: number;
  basicAmount: string;
  discount: string;
  netAmount: string;
  sgst: string;
  cgst: string;
  igst: string;
  additionalCharges: string;
  grossTotal: string;
  createdDate: string;
  createdBy: number;
  modifiedDate: string | null;
  modifiedBy: number | null;
  archieved: number;
  rejectedReason: string;
  approvalStatus: string;
  status: string;
  deliveryStatus: string;
  lineItems: LineItem[];
  eventChains: EventChain[];
  supplierName: string;
  siteName: string;
  costCenterName: string;
  createdUserName: string;
  createdUserId: number;
  currencyCode: string;
  currency: string;
  currencySymbol: string;
}

// interface ApiResponse {
//   status: boolean;
//   message: string;
//   response: Item;
// }

interface SelectOptions {
  label: string;
  value: string;
}

const ViewBudgetDetails: FC<any> = () => {
  const [sm, setSm] = useState(false);
  const [tab, setTab] = useState("1");
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState("1");
  const [instructionsVisible, setInstructionsVisible] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);
  const [materialData, setMaterialData] = useState<any>({});
  const [attachments, setAttachments] = useState<any>([]);
  const [roles, setRoles] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [actionStatus, setActionStatus] = useState('');
  const [showActionModelVisible, setShowActionModelVisible] = useState(false);
  const [costCenterOptions, setCostCenterOptions] = useState<SelectOptions[]>(
    []
  );
  const [form] = Form.useForm();

  const [sitesOptions, setSitesOptions] = useState<SelectOptions[]>([]);

  const [departmentOptions, setDepartmentOptions] = useState<SelectOptions[]>(
    []
  );

  const [uomOptions, setUomOptions] = useState<SelectOptions[]>([]);
  const [materialCategoryOptions, setMaterialCategoryOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState<SelectOptions[]>([]);
  const [DMS, setDMS] = useState<any>([]);
  const [editLineRow, setEditLineRow] = useState<any>();
  const [changeToBuyerData, setChangeToBuyerData] = useState<any>();
  const [showSubmitBtm, setShowSubmitBtm] = useState(false);
  const [generalInformationEdit, setGeneralInformationEdit] = useState(false);
  const navigate = useNavigate();
  const [deletedAttachments, setDeletedAttachments] = useState<any>([]);
  const [newAttachments, setNewAttachments] = useState<any>([]);
  const [newTermsAndConditions, setNewTermsAndConditions] = useState<any>("");
  const [instructions, setInstructions] = useState("");
  const [newInstructions, setNewInstructions] = useState<any>("");
  const [isTermsAndConditionsChanged, setIsTermsAndConditionsChanged] =
    useState(false);
  const [isInstructionsChanged, setIsInstructionsChanged] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState<any>("");
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [completedDateSelectedValue, setCompletedDateSelectedValue] = useState<any>('');

  const methods = useForm({
    mode: "onChange",
  });

  let { budgetId }: any = useParams();

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const [addNewRowModel, setAddNewRowModel] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };
  const fetchBudgetDetails = async () => {
    try {
      const res = await budgetApis.fetchBudgetById(budgetId);
      const { response, status, message } = res;
      if (status) {
        setIsLoading(false);
        setDMS(response.docs || []);
        setMaterialData(response);
        console.log("response.lines", response.lines);
        setData(response.lines || []);
        setShowSubmitBtm(false);
        setDeletedAttachments([]);
        // setNewAttachments([]);
        const _attachments = response?.docs?.filter(
          (item: any) => item.moduleFileType == "Special Instructions"
        );
        console.log("_attachments", _attachments)
        if (_attachments) {
          console.log("_attachments", _attachments)
          setAttachments(
            _attachments.map((attachment: any) => ({
              docId: attachment.docId,
              name: attachment.fileName,
              link: attachment.filePath,
              moduleFileType: attachment.moduleFileType,
              module: attachment.module,
              label: "attachments",
              isActive: true,
            }))
          );
        }
        const _termsAndConditions = response?.docs?.find(
          (item: any) => item.moduleFileType == "termsAndConditions"
        );
        if (_termsAndConditions) {
          const data: any = await fetchHtmlContentFromUrl(
            _termsAndConditions?.filePath
          );
          setTermsAndConditions(data);
          setNewTermsAndConditions(data);
        }
        const _instructions = response?.docs?.find(
          (item: any) => item.moduleFileType == "instructions"
        );
        if (_instructions) {
          const data: any = await fetchHtmlContentFromUrl(
            _instructions?.filePath
          );
          setInstructions(data);
          setNewInstructions(data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchHtmlContentFromUrl = async (url: string) => {
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "text/html",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };


  useEffect(() => {
    fetchBudgetDetails();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const fetchCostCenter = async () => {
    try {
      const { data, status, message } = await costCenterApis.fetchCostCenter();
      if (status) {
        let costCenterOptions = data.map((item: any) => {
          return { label: item.costCenterName, value: item.id.toString() };
        });
        setCostCenterOptions(costCenterOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSites = async () => {
    try {
      const res = await sites.fetchSites();
      const { data, status, message } = res;
      if (status) {
        let sitesOptions = data.map((item: any) => {
          return { label: item.name, value: item.siteID.toString() };
        });
        setSitesOptions(sitesOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onValuesChange = (_: any, allValues: any) => {
    console.log("Form values changed: ", allValues); // Debugging - check form values
  };

  const fetchDepartment = async () => {
    try {
      const { data, status, message } = await departments.fetchDeptData();
      if (status) {
        let departmentOptions = data.map((item: any) => {
          return { label: item.deptName, value: item.deptID.toString() };
        });
        setDepartmentOptions(departmentOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchModuleConfig();
    // fetchBillingAddress();
    // fetchDeliveryAddress();
    // fetchSupplier();
    fetchCostCenter();
    fetchSites();
    // fetchGeneralLedger();
    fetchDepartment();
    // fetchCurrencyData();
    // fetchUOMOPtions();
    fetchCategoryOptions();
  }, []);

  const [columns, setColumns] = useState<TableColumnsType<Item>>([
    // {
    //   ...Table.EXPAND_COLUMN,
    //   fixed: "left",
    // },
    {
      title: "Budget Line",
      dataIndex: "budgetLineName",
      key: "budgetLineName",
      fixed: "left" as const,
      width: 130,
    },
    {
      title: "Budget Line Description",
      dataIndex: "lineDescription",
      key: "lineDescription",
      // fixed: 'left' as const,
      width: 200,
    },
    {
      title: "Budget Value",
      dataIndex: "budgetValue",
      key: "budgetValue",
      width: 150,
      render: (text) => {
        const value = parseFloat(text);
        return isNaN(value) ? "0.00" : value.toFixed(2);
      },
    },
    {
      title: "Planned Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: 200,
      render: (text) => moment(text).format("DD-MMM-YYYY"),
    },
    {
      title: "Planned End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text) => moment(text).format("DD-MMM-YYYY"),
    },
    {
      title: "Bill Of Material (BOM)",
      dataIndex: "bom",
      key: "bom",
    },
    {
      title: "BOM Allocation",
      dataIndex: "allocation",
      key: "allocation",
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right" as const,
      width: 100,
      render: (text, record: any, index) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              console.log("record", record);
              setEditLineRow(record);
            }}
          >
            <Icon name="edit" />
          </Button>
        </>
      ),
    },
  ]);

  useEffect(() => {
    console.log("lines", editLineRow);
    if (editLineRow) {
      setAddNewRowModel(true);
    }
  }, [editLineRow]);

  const fetchCategoryOptions = async () => {
    try {
      const { data, status, message } = await adminOrgApis.fetchAdminMaterial();
      const { data: materialCategoryOptions } =
        await adminOrgApis.fetchAdminMaterialCategory();
      if (status) {
        setMaterialCategoryOptions(data);
        setCategoryOptions(
          materialCategoryOptions.map((item: any) => {
            return {
              label: item.category,
              value: item.id,
            };
          })
        );
      } else {
        console.log(message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchApprovalHierarchyUsersByModuleId = async (moduleID: number) => {
    try {
      // const res = await rolesApi.fetchHierarchyUsersByModuleId(moduleID);
      // if (!res) return;
      // const { data, status } = res;
      // if (status) {
      //   setRoles(data);
      // }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchModuleConfig = async () => {
    try {
      const res = await rolesApi.getModuleConfig();
      const moduleID = res?.data?.find(
        (x: any) => x.moduleName == "Purchase Order"
      )?.moduleID;
      console.log(moduleID);
      await fetchApprovalHierarchyUsersByModuleId(moduleID);
    } catch (err) {
      throw err;
    }
  };

  const ApprovalTimelineContent = () => {
    const approvalsData = materialData?.approvals;
    if (!approvalsData || approvalsData?.length === 0)
      return <Badge color="outline-danger">No</Badge>;
    return (
      <Popover
        content={
          <Timeline className="approval-timeline">
            {approvalsData?.map((approval: any, index: number) => {
              const isApproved = approval.status == "IN_PROGRESS";
              const isRejected = approval.status == "REJECTED";
              return (
                <Timeline.Item
                  key={index}
                  dot={
                    isApproved ? (
                      <CheckCircleOutlined style={{ color: "green" }} />
                    ) : isRejected ? (
                      <CloseCircleOutlined style={{ color: "red" }} />
                    ) : undefined
                  }
                >
                  {approval?.userName}
                  <div className="designation">{approval?.roleName}</div>
                  {approval?.approvalDate &&
                    <div className="designation">{dayjs(approval?.approvalDate).format("DD-MMM-YYYY")}</div>
                  }
                </Timeline.Item>
              );
            })}
          </Timeline>
        }
        title={<span style={{ color: "#2263b3" }}>Approval Hierarchy</span>}
      >
        <span
          style={{
            color: "#2263b3",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        // onClick={() => {
        //   setApprovals({
        //     open: true,
        //     data: approvalsData,
        //   });
        // }}
        >
          Yes
        </span>
      </Popover>
    );
  };

  const append = (data: any) => {
    console.log("append", data);
    let newLineItems = materialData?.lines;
    const _data = newLineItems.find((item: any) => {
      if (
        item.budgetLineName == data.budgetLineName
      )
        return true;
    });

    if (_data) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Material Already Added",
      });
      return;
    }
    setMaterialData((prev: any) => {
      let newData = {
        ...prev,
        lines: [...prev.lines, data], // Add new data to lines immutably
      };
      console.log(newData, "newData");
      return newData;
    });
    setData((pre: any) => {
      console.log("pre", pre);
      let newData = {
        ...pre,
        lines: [...pre, data], // Add new data to lines immutably
      };
      console.log(newData, "newData");
      return newData.lines;
    });
    setShowSubmitBtm(true);

  };

  // Update existing row handler
  const update = (id: any, data: any) => {
    console.log("update", data, id);
    let newLineItems = materialData?.lines;
    console.log("newLineItems", newLineItems)
    const _data = newLineItems
      .filter((item: any) => item.lineId != id)
      .find((item: any) => {
        if (
          item.budgetLineName == data.budgetLineName
          // &&
          // dayjs(item.needByDate).format("DD-MMM-YYYY") ==
          // dayjs(data.needByDate).format(
          //     "DD-MMM-YYYY"
          // ) &&
          // item.deliveryAddressId == data.deliveryAddressId
        )
          return true;
      });
    console.log("_dataaa", _data)
    if (_data) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: " Material Already Added",
      });
      return;
    }

    const updatedLines = newLineItems.map((item: any) => {
      if (item.lineId == id) {
        return data;
      }
      return item;
    });

    setMaterialData((prev: any) => ({
      ...prev,
      lines: updatedLines, // Update lines immutably
    }));

    setData(updatedLines);
    setShowSubmitBtm(true);
  };

  const onSubmit = async (data: any, tab: any) => {
    console.log(data);
    const updatedResponse = {
      ...materialData, // Spread the existing response data
      ...data, // Spread the edited form data to overwrite the necessary fields
    };
    console.log("updatedResponse", updatedResponse);
    setMaterialData(updatedResponse);
    setGeneralInformationEdit(false);
  };

  const updateBudgetLines = (data: any) => {
    console.log("updateBudgetLines", data);
    setMaterialData((prevData: any) => {
      let newData = { ...prevData };
      newData?.budgetLines?.push(data);
      return newData;
    });
    setData((prevData: any) => [...prevData, data]);
    console.log("materialData", materialData, data);
  };

  const handleAttachmentChange = (info: { fileList: UploadFile[] }) => {
    setNewAttachments((pre: any) => {
      return [...pre, ...info.fileList];
    });
    setShowSubmitBtm(true);
  };

  const submitSave = () => {
    const deletedFileData: any = [...deletedAttachments];
    const newfiledata: any = [];
    console.log("DMS", DMS)
    const formDataToSend = new FormData();
    console.log("newAttachments", newAttachments);
    // Map the new attachments to the desired format
    newAttachments.forEach((attachment: any, index: number) => {
      if (!attachment) {
        return;
      }
      const file = new File([attachment], attachment.name, {
        type: attachment.type,
        lastModified: attachment.lastModified,
      });
      formDataToSend.append("files", file);
      newfiledata.push({
        fileIndex: newfiledata.length, // Continue the index after existing attachments
        module: "Budget",
        moduleFileType: "Special Instructions", // Adjust as necessary
        level: "HEADER_LEVEL", // Adjust as necessary
        // budgetId: BudgetData.budgetId, // Assuming you have access to BudgetData
      });
    });

    // console.log("Lines:", lines);
    // console.log("Docs:", docs);
    // console.log("Approvals:", approvals);
    // console.log("Remaining Data:", remainingData);



    if (isInstructionsChanged) {
      const instructionsFile = new Blob([newInstructions], {
        type: "text/html",
      });
      formDataToSend.append(
        `files`,
        new File([instructionsFile], "instructions.html", {
          type: "text/html",
        })
      );
      newfiledata.push({
        fileIndex: newfiledata.length,
        module: "Budget",
        moduleFileType: "instructions",
        level: "HEADER_LEVEL",
      });
      setIsInstructionsChanged(false);
      const _instructions = DMS?.find(
        (item: any) => item.moduleFileType == "instructions"
      );
      if (_instructions?.docId) {
        deletedAttachments.push({
          docId: _instructions.docId,
        });
      }
    }
    if (isTermsAndConditionsChanged) {
      console.log("isTermsAndConditionsChanged",)
      const termsAndConditionsFile = new Blob([newTermsAndConditions], {
        type: "text/html",
      });
      formDataToSend.append(
        `files`,
        new File([termsAndConditionsFile], "termsAndConditions.html", {
          type: "text/html",
        })
      );
      newfiledata.push({
        fileIndex: newfiledata.length,
        module: "Budget",
        moduleFileType: "termsAndConditions",
        level: "HEADER_LEVEL",
      });
      setIsTermsAndConditionsChanged(false);
      const _termsAndConditions = DMS?.find(
        (item: any) => item.moduleFileType == "termsAndConditions"
      );
      if (_termsAndConditions?.docId) {
        console.log("deletedDocIds", _termsAndConditions.docId);
        deletedAttachments.push({
          docId: _termsAndConditions.docId,
        });
      }
    }


    console.log("deletedFileData", deletedFileData)



    materialData.docs = [...materialData.docs, ...newfiledata];
    console.log("updatedDocs", materialData.docs);

    const { lines, docs, approvals, ...remainingData } = materialData;
    const submissionData = {
      header: remainingData,
      lines: lines,
      approvals: approvals,
      docKeys: docs,
    };

    formDataToSend.append("header", JSON.stringify(submissionData.header));
    formDataToSend.append("lines", JSON.stringify(submissionData.lines));
    formDataToSend.append(
      "approvals",
      JSON.stringify(submissionData.approvals)
    );
    formDataToSend.append("docKeys", JSON.stringify(submissionData.docKeys));
    const deletedDocIds = deletedAttachments.map(
      (deleted: any) => deleted.docId
    );
    console.log("deletedDocIds", deletedDocIds);
    formDataToSend.append(
      "deletedId",
      JSON.stringify(deletedDocIds.length > 0 ? deletedDocIds : [])
    );
    // newAttachments.forEach((attachment: any) => {
    //   const file = new File([attachment], attachment.name, {
    //     type: attachment.type,
    //     lastModified: attachment.lastModified,
    //   });
    //   formDataToSend.append("files1111", file);
    // });
    console.log("formDataToSend", formDataToSend)
    // addRow(data);
    BudgetCreation(formDataToSend);
  };

  const BudgetCreation = async (data: any) => {
    console.log("data", data);
    // return;
    try {
      const response: any = await budgetApis.createNewVersionBudget(data);
      console.log(response);
      const { status, message } = response;
      if (status) {
        Swal.fire("Updated!", "Budget has been Updated", "success");
        navigate(`/view-budget`);
        fetchBudgetDetails();
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    }
    setDisableSubmit(false);
  };


  const hold = async () => {
    setActionStatus("Hold");
    setShowActionModelVisible(true);
  }

  const complete = async () => {
    setActionStatus("Complete");
    setShowActionModelVisible(true);
  }

  const inactive = async () => {
    setActionStatus("Inactive");
    setShowActionModelVisible(true);
  }

  const handleCancel = () => {
    setShowActionModelVisible(false);
    form.resetFields();
  };


  const apiCallToHoldBudget = async (headerId: any, reason: string) => {
    // Replace this with your actual API call logic
    try {
      let data = {
        headerId: Number(headerId),
        remaks: reason,
      }
      const res = await budgetApis.holdBudget(data);
      const { response, status, message } = res;
      console.log("data", response);
      if (res.status) {
        Swal.fire("Hold!", "Budget has been Hold", "success");
        fetchBudgetDetails();
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    }
  };

  const apiCallToInactiveBudget = async (headerId: any, reason: string) => {
    // Replace this with your actual API call logic
    try {
      let data = {
        headerId: Number(headerId),
        remaks: reason,
      }
      const res = await budgetApis.inactivateBudget(data);
      const { response, status, message } = res;
      console.log("data", response);
      if (res.status) {
        Swal.fire("Inactive!", "Budget has been Inactive", "success");
        fetchBudgetDetails();
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    }
  };

  const apiCallToCompleteBudget = async (headerId: any, reason: string,date: any) => {
    // Replace this with your actual API call logic
    try {
      let data = {
        headerId: Number(headerId),
        remaks: reason,
        completedDate: dayjs(date).format('YYYY-MM-DD')
      }
      const res = await budgetApis.completeBudget(data);
      const { response, status, message } = res;
      console.log("data", response);
      if (res.status) {
        Swal.fire("Completed!", "Budget is marked as Completed", "success");
        fetchBudgetDetails();
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    }
  };

  const handleOk = async () => {
    console.log("11111")
    if (actionStatus === "Hold") {
      console.log("HOLD", actionStatus)
      try {
        const values = await form.validateFields();
        await apiCallToHoldBudget(budgetId, values.reason);
        setShowActionModelVisible(false);
        form.resetFields();
      } catch (error) {
      }
    }
    if (actionStatus === "Inactive") {
      console.log("Inactive", actionStatus)
      try {
        const values = await form.validateFields();
        await apiCallToInactiveBudget(budgetId, values.inActiveReason);
        setShowActionModelVisible(false);
        form.resetFields();
      } catch (error) {
      }
    }
    if (actionStatus === "Complete") {
      console.log("actionStatus", actionStatus)
      const values = await form.validateFields(); // Validate all fields
      console.log("values", values)
      console.log("Completion Date:", dayjs(values.completionDate).format("DD-MMM-YYYY"));
      console.log("Reason for Delay:", values.reasonForDelay);
      try {
        const values = await form.validateFields();
        await apiCallToCompleteBudget(budgetId, values.reasonForDelay, dayjs(values.completionDate).format("DD-MMM-YYYY"));
        setShowActionModelVisible(false);
        form.resetFields();
      } catch (error) {
      }
    }
  }


  return (
    <>
      <Head title="Budget - Track" />
      <Modal
        isOpen={generalInformationEdit}
        toggle={() => setGeneralInformationEdit(false)}
        className="modal-dialog-centered"
        size="xl"
        backdrop="static"
      >
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            setGeneralInformationEdit(false);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>

        <ModalBody>
          {/* {tab === "1" && ( */}
          <>
            <h5 className="title">Update General Information</h5>
            <div style={{ marginTop: "15px" }}></div>

            <FormProvider {...methods}>
              <form
                className="content clearfix"
                onSubmit={handleSubmit((data) => {
                  onSubmit(data, 1);
                  setShowSubmitBtm(true);
                })}
              >
                <Row className="gy-4">
                  <Col lg="4">
                    <FormInput
                      name="budgetName"
                      label="Budget Name"
                      required={true}
                      placeholder="Enter Budget Name"
                    />
                  </Col>
                  <Col lg="4">
                    <FormDatePicker
                      name="startDate"
                      label="Planned Start Date"
                      required={true}
                    />
                  </Col>
                  <Col lg="4">
                    <FormDatePicker
                      name="endDate"
                      label="Planned End Date"
                      required={true}
                    />
                  </Col>
                  <Col lg="12">
                    <FormTextArea
                      name="description"
                      label="Budget Description"
                      required={true}
                      placeholder="Enter Budget Description"
                    />
                  </Col>
                  <Col lg="3">
                    <FormSelect
                      name="budgetFor"
                      label="Budget for"
                      defaultValue={{
                        label: "Site Procurement",
                        value: "Procurement",
                      }}
                      options={[
                        { label: "Site Procurement", value: "Procurement" },
                        { label: "Site Operations", value: "Operations" },
                      ]}
                      required={true}
                      placeholder="Select"
                    />
                  </Col>
                  <Col lg="3">
                    <FormSelect
                      name="site"
                      label="Select Site"
                      options={sitesOptions}
                      required={true}
                      placeholder="Select"
                    />
                  </Col>
                  <Col lg="3">
                    <FormSelect
                      name="lob"
                      label="Select LOB"
                      defaultValue={{
                        label: "Construction",
                        value: "Construction",
                      }}
                      options={[
                        { label: "Construction", value: "Construction" },
                        { label: "Others", value: "Others" },
                      ]}
                      required={true}
                      placeholder="Select"
                    />
                  </Col>
                  <Col lg="3">
                    <FormSelect
                      name="costCenter"
                      label="Cost Center"
                      options={costCenterOptions}
                      required={false}
                      placeholder="Select"
                    />
                  </Col>
                  <Col lg="3">
                    <FormSelect
                      name="department"
                      label="Department"
                      options={departmentOptions}
                      required={true}
                      placeholder="Select"
                    />
                  </Col>

                  {/* <Col lg="3">
                    <FormSelect
                      name="approvalRequired"
                      label="Approval Required ?"
                      options={[
                        { label: "Yes", value: "Y" },
                        { label: "No", value: "N" },
                      ]}
                      placeholder="Select"
                      required={true}
                    />
                    <div style={{ display: "none" }}>
                      <FormInput name="approvalsData" label="" type="text" />
                    </div>
                    {watch("approvalRequired") == "Y" && (
                      <Popover
                        content="Click here to set required approvals"
                        title="Approval Required"
                      >
                        <a
                          href="#"
                          className="success"
                          onClick={(e) => {
                            e.preventDefault();
                            setApprovals((pre) => {
                              return {
                                ...pre,
                                open: true,
                              };
                            });
                          }}
                        >
                          <EditOutlined /> Set Approvals &nbsp;
                          {approvals?.data?.length == 0 ? (
                            <span className="mandatory">
                              Please set approvals
                            </span>
                          ) : (
                            <></>
                          )}
                        </a>
                      </Popover>
                    )}
                  </Col>
                  <Col lg="3">
                    <FormSelect
                      name="docRequired"
                      label="Require Documents?"
                      options={[
                        { label: "Yes", value: "Y" },
                        { label: "No", value: "N" },
                      ]}
                      required={false}
                      placeholder="Select"
                      // onChange={(val) =>
                      //   updateFormState({ showAskDocs: val === "Y" })
                      // }
                    />
                    {formState.showAskDocs && (
                      <Popover
                        content="Click here to ask required documents"
                        title="Documents Required"
                      >
                        <a
                          href="#"
                          className="success"
                          onClick={(e) => {
                            e.preventDefault();
                            showAskDocumentsDrawer();
                          }}
                        >
                          <EditOutlined /> Ask Required Documents &nbsp;
                          {formState.documentsPopoverVisible ? (
                            <span className="mandatory">
                              Please set documents
                            </span>
                          ) : (
                            <></>
                          )}
                        </a>
                      </Popover>
                    )}
                  </Col> */}
                </Row>
                <Row className="mt-4">
                  <Col lg="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          className="btn btn-primary btn-md"
                          type="submit"
                          color="primary"
                        >
                          Save
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setGeneralInformationEdit(false);
                            reset();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </form>
            </FormProvider>
          </>
          {/* )} */}
        </ModalBody>
      </Modal>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            {isLoading == true ? (
              <>
                <Flex align="center" gap="middle" className="pageLoader">
                  <Spin size="large" className="m-auto" />
                </Flex>
              </>
            ) : (
              <div className="card-inner card-inner-lg">
                {sm && mobileView && (
                  <div
                    className="toggle-overlay"
                    onClick={() => setSm(!sm)}
                  ></div>
                )}
                <BlockHead size="lg">
                  <BlockBetween>
                    <BlockHeadContent>
                      <BlockTitle tag="h4">
                        <span>
                          Budget ID - {materialData?.budgetId}
                          {/* {(statusMapper[rfqStatus] &&
                              statusMapper[rfqStatus]?.buyer?.title) ||
                              rfqStatus}{" "} */}
                        </span>
                      </BlockTitle>
                    </BlockHeadContent>
                    <div className="d-flex">
                      {showSubmitBtm && (
                        <div className="actions clearfix">
                          <ul
                            className="m-auto d-flex align-items-center justify-content-center p-0"
                            style={{
                              flexDirection: "row-reverse",
                            }}
                          >
                            <li className="pt-0 pb-0">
                              {disableSubmit ? (
                                <Button color="primary" type="button">
                                  <Spinner size="sm" color="light" />
                                </Button>
                              ) : (
                                <Button
                                  color="primary"
                                  type="button"
                                  onClick={() => {
                                    setDisableSubmit(true);
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: "Do you want to submit the changes?",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, submit it!",
                                      cancelButtonText: "No, cancel",
                                    }).then(async (result) => {
                                      if (result.isConfirmed) {
                                        await submitSave();
                                      } else {
                                        setDisableSubmit(false);
                                      }
                                    });
                                  }}
                                >
                                  Submit
                                </Button>
                              )}
                            </li>
                            <li className="pt-0 pb-0">
                              <Button
                                color="secondary"
                                onClick={() => {
                                  Swal.fire({
                                    title: "Are you sure?",
                                    text: "Do you want to revert the changes?",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, revert it!",
                                    cancelButtonText: "No, cancel",
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      fetchBudgetDetails();
                                      Swal.fire(
                                        "Reverted!",
                                        "Your changes have been reverted.",
                                        "success"
                                      );
                                    }
                                  });
                                }}
                                type="button"
                                className="btn-dim btn btn-secondary"
                              >
                                Revert
                              </Button>
                            </li>
                          </ul>
                        </div>
                      )}


                      {
                        materialData?.status == "Active" && (
                          <div className="">
                            <ul className="nk-tb-actions"
                            >
                              <li>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-v"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                      <li onClick={() => {
                                        {
                                          Swal.fire({
                                            title: "Are you sure?",
                                            text: "Do you want to Hold this Budget?",
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText: "Yes, Hold it!",
                                            cancelButtonText: "No, cancel",
                                          }).then((result) => {
                                            if (result.isConfirmed) {
                                              hold();
                                            } else {
                                              setDisableSubmit(false);
                                            }
                                          });
                                        }
                                      }}>
                                        <Link to={`#`}>
                                          <Icon className="text-danger" name="pause-circle"></Icon>
                                          <span>Hold Budget</span>
                                        </Link>
                                      </li>
                                      <li
                                        onClick={() => {
                                          {
                                            Swal.fire({
                                              title: "Are you sure?",
                                              text: "Do you want to Complete this Budget?",
                                              icon: "warning",
                                              showCancelButton: true,
                                              confirmButtonColor: "#3085d6",
                                              cancelButtonColor: "#d33",
                                              confirmButtonText: "Yes, Complete it!",
                                              cancelButtonText: "No, cancel",
                                            }).then((result) => {
                                              if (result.isConfirmed) {
                                                complete();
                                              } else {
                                                setDisableSubmit(false);
                                              }
                                            });
                                          }
                                        }}
                                      >
                                        <Link to={`#`}>
                                          <Icon className="text-success" name="check-circle"></Icon>
                                          <span>Complete Budget</span>
                                        </Link>
                                      </li>
                                      <li onClick={() => {
                                        {
                                          Swal.fire({
                                            title: "Are you sure?",
                                            text: "Do you want to Inactive this Budget?",
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText: "Yes, Inactive it!",
                                            cancelButtonText: "No, cancel",
                                          }).then((result) => {
                                            if (result.isConfirmed) {
                                              inactive();
                                            } else {
                                              setDisableSubmit(false);
                                            }
                                          });
                                        }
                                      }}>
                                        <Link to={`#`}>
                                          <Icon className="text-warning" name="cross-circle"></Icon>
                                          <span>Inactive Budget</span>
                                        </Link>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </li>
                              {/* <li className="pt-0 pb-0">
                                {disableSubmit ? (
                                  <Button color="primary" type="button">
                                    <Spinner size="sm" color="light" />
                                  </Button>
                                ) : (
                                  <Button
                                    color="primary"
                                    type="button"
                                    onClick={() => {
                                      setDisableSubmit(true);
                                      Swal.fire({
                                        title: "Are you sure?",
                                        text: "Do you want to Hold this Budget?",
                                        icon: "warning",
                                        input: "textarea", // This adds the input field for remarks
                                        inputPlaceholder: "Enter your remarks here...",
                                        inputAttributes: {
                                          "aria-label": "Enter your remarks",
                                        },
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Yes, Hold it!",
                                        cancelButtonText: "No, cancel",
                                        preConfirm: (remarks) => {
                                          if (!remarks) {
                                            Swal.showValidationMessage("Remarks are required");
                                          }
                                          return remarks;
                                        },
                                      }).then(async (result) => {
                                        if (result.isConfirmed && result.value) {
                                          const remarks = result.value; // Capture the remarks from the Swal input
                                          await hold(remarks); // Pass remarks to the hold API
                                        } else {
                                          setDisableSubmit(false);
                                        }
                                      });
                                    }}
                                  >
                                    Hold
                                  </Button>
                                )}
                              </li>
                              <li className="pt-0 pb-0">
                                <Button
                                  color="secondary"
                                  onClick={() => {
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: "Do you want to Complete this Budget?",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, Complete it!",
                                      cancelButtonText: "No, cancel",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        complete();
                                      } else {
                                        setDisableSubmit(false);
                                      }
                                    });
                                  }}
                                  type="button"
                                  className="btn-dim btn btn-secondary"
                                >
                                  Complete
                                </Button>
                              </li> */}
                            </ul>
                          </div>
                        )
                      }

                      {!showSubmitBtm && (
                        <div className="paddingLeft10">
                          <Button
                            className="toggle"
                            color="primary"
                            onClick={async () => {
                              navigate("/view-budget");
                            }}
                          >
                            <Icon name="arrow-left" />
                            <span>Back</span>
                          </Button>
                        </div>
                      )}


                    </div>
                    <BlockHeadContent className="align-self-start d-lg-none">
                      <Button
                        className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""
                          }`}
                        onClick={() => setSm(!sm)}
                      >
                        <Icon name="menu-alt-r" />
                      </Button>
                    </BlockHeadContent>
                  </BlockBetween>
                </BlockHead>
                <div className="content clearfix">
                  <AntCollapse defaultActiveKey={["1", "2", "3"]}>
                    <Panel header="General Information" key="1">
                      <span
                        className="cursor-pointer position-absolute"
                        style={{
                          right: 43,
                          top: 73,
                          fontSize: "18px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (tab === "1") {
                            setValue("budgetName", materialData?.budgetName);
                            setValue("startDate", dayjs(materialData?.startDate));
                            setValue("endDate", dayjs(materialData?.endDate));
                            setValue("description", materialData?.description);
                            setValue("budgetFor", materialData?.budgetFor);
                            setValue("site", materialData?.site);
                            setValue("lob", materialData?.lob);
                            setValue("approvalRequired", materialData?.approvalRequired);
                            setValue("costCenter", materialData?.costCenter);
                            setValue("department", materialData?.department);
                          }
                          setGeneralInformationEdit(true);
                        }}
                      >
                        <Icon name="edit" />
                      </span>
                      <div>
                        <Col className="justify-start w-100  flex-wrap  p-3">
                          <div className="w-35 border-end ">
                            <div className="data-col align-center pt-1 ">
                              <div className="data-label w-30">Budget Name</div>
                              <div className="data-value w-70 ">
                                {materialData?.budgetName}
                              </div>
                            </div>
                            <div className="data-col align-center pt-1 ">
                              <div className="data-label w-30">Planned Start Date</div>
                              <div className="data-value w-70 ">
                                {moment(materialData?.startDate).format("DD-MMM-YYYY")}
                              </div>
                            </div>
                            <div className="data-col align-center pt-1">
                              <div className="data-label w-30">Planned End Date</div>
                              <div className="data-value w-70">
                                {moment(materialData?.endDate).format("DD-MMM-YYYY")}
                              </div>
                            </div>
                            <div className="data-col align-center pt-1">
                              <div className="data-label w-30">Budget Description</div>
                              <div className="data-value w-70">
                                {materialData?.description}
                                {/* <ApprovalTimelineContent /> */}
                              </div>
                            </div>
                          </div>
                          <div className="w-30 border-end paddingLeft10 ">
                            <div className="data-col align-center pt-1 ">
                              <div className="data-label w-30">Budget for</div>
                              <div className="data-value w-70 ">
                                {materialData?.budgetFor}
                              </div>
                            </div>

                            <div className="data-col align-center pt-1 ">
                              <div className="data-label w-30">Site</div>
                              <div className="data-value w-70 ">
                                {materialData?.siteName}
                              </div>
                            </div>
                            <div className="data-col align-center pt-1 ">
                              <div className="data-label w-30">LOB</div>
                              <div className="data-value w-70 ">{materialData?.lob}</div>
                            </div>
                            <div className="data-col align-center pt-1 ">
                              <div className="data-label w-30">Cost Center</div>
                              <div className="data-value w-70 ">
                                {materialData?.costCenterName}
                              </div>
                            </div>
                          </div>
                          <div className="w-35 paddingLeft10">
                            <div className="data-col align-center pt-1 ">
                              <div className="data-label w-30">Department</div>
                              <div className="data-value w-70 ">
                                {materialData?.departmentName}
                              </div>
                            </div>

                            <div className="data-col align-center pt-1 ">
                              <div className="data-label w-30">Approval Required</div>
                              <div className="data-value w-70 ">
                                <ApprovalTimelineContent />
                                {/* {materialData?.approvalRequired
                        ? "Y" && (
                            <>
                              <ApprovalTimelineContent />
                            </>
                          )
                        : "N" && "No"} */}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </Panel>
                    <Panel header="Material Informations" key="2">
                      {/* <div className="table-responsive poTable"> */}
                      {/* <div
                            style={{
                              width: screenSize - 380 + "px",
                              margin: "15px auto",
                              // minHeight: "200px",
                            }}
                          > */}
                      {data && (
                        <Table
                          className="customTable"
                          columns={columns}
                          dataSource={data?.map((item: any) => ({
                            ...item,
                            key: item?.lineNo,
                          }))}
                          scroll={{ x: 1000 }}
                          // expandable={{
                          //   expandedRowRender: (record: any) => (
                          //     <div className="expandableBody">
                          //       <div style={{ flex: 1, textAlign: "left" }}>
                          //         <p style={{ margin: 0 }}>{record.description}</p>
                          //         {record.file && (
                          //           <div>
                          //             <PaperClipOutlined /> &nbsp;
                          //             <a
                          //               href={record?.file?.fileName}
                          //               download={record?.file?.fileName}
                          //             >
                          //               {record?.file?.fileName}
                          //             </a>
                          //           </div>
                          //         )}
                          //       </div>
                          //     </div>
                          //   ),
                          // }}
                          locale={{
                            emptyText: (
                              <span
                                style={{
                                  height: "300px",
                                  color: "#333",
                                  fontSize: "16px",
                                }}
                              >
                                Please click on "Add New Item" to add Budget
                                lines.
                              </span>
                            ),
                          }}
                          bordered
                          size="middle"
                          pagination={false}
                        />
                      )}
                      {/* </div> */}
                      {data?.length > 0 && (
                        <Row
                          style={{
                            margin: "20px",
                          }}
                        >
                          <a
                            onClick={() => {
                              setAddNewRowModel(true);
                              setEditLineRow(null);
                            }}
                            href="#add"
                            className="addNewItem"
                          >
                            <Icon name="plus-round" />
                            <span>Add New Item</span>
                          </a>
                        </Row>
                      )}
                      {/* </div> */}
                    </Panel>
                    <Panel header="Other Information" key="3">
                      <Descriptions title="" className="custom-description-title">
                        <Descriptions.Item label="Instructions">
                          <span
                            style={{
                              color: "#2263b3",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => setInstructionsVisible(true)}
                          >
                            View
                          </span>
                        </Descriptions.Item>
                        <Descriptions.Item label="Terms and Conditions">
                          <span
                            style={{
                              color: "#2263b3",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => setTermsVisible(true)}
                          >
                            View
                          </span>
                        </Descriptions.Item>
                        {/* {attachments?.length > 0 && ( */}
                        <Descriptions.Item label="">
                          <div>
                            <strong className="">
                              Attachments &nbsp;
                              <PaperClipOutlined />
                            </strong>
                            <div>
                              {attachments?.map((attachment: any, index: number) => (
                                <div
                                  key={index}
                                  className="w-100 d-flex justify-content-between"
                                >
                                  <div>
                                    <strong>{index + 1}.</strong>
                                    &nbsp;
                                    {attachment.name}
                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                  </div>
                                  <div>
                                    <a
                                      href={attachment.link}
                                      download={attachment.link}
                                      style={{ color: "black", fontSize: "18px" }}
                                    >
                                      <DownloadOutlined />
                                    </a>
                                    &nbsp; &nbsp;
                                    <div
                                      style={{
                                        display: "inline",
                                        cursor: "pointer",
                                        color: "red",
                                        fontSize: "16px",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault(); // Prevent the default anchor behavior
                                        setDeletedAttachments((prev: any) => [
                                          ...prev,
                                          { docId: attachment?.docId },
                                        ]);
                                        let updatedAttachmentsList =
                                          attachments?.filter(
                                            (item: any, i: number) => i !== index
                                          );
                                        setAttachments(updatedAttachmentsList);
                                        setShowSubmitBtm(true);
                                      }}
                                    >
                                      <DeleteOutlined />
                                    </div>
                                  </div>
                                </div>
                              ))}
                              {newAttachments?.map((attachment: any, index: number) => (
                                <div
                                  key={index}
                                  className="w-100 d-flex justify-content-between"
                                >
                                  <div>
                                    <strong>{attachments?.length + index + 1}.</strong>
                                    &nbsp;
                                    {attachment.name}
                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                  </div>
                                  <div>
                                    <a
                                      href={attachment.link}
                                      download={attachment.link}
                                      style={{
                                        color: "black",
                                        fontSize: "18px",
                                      }}
                                    >
                                      <DownloadOutlined />
                                    </a>
                                    &nbsp; &nbsp;
                                    <div
                                      style={{
                                        display: "inline",
                                        cursor: "pointer",
                                        color: "red",
                                        fontSize: "16px",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault(); // Prevent the default anchor behavior
                                        setNewAttachments((prev: any) =>
                                          prev.filter(
                                            (item: any, i: number) => i !== index
                                          )
                                        );
                                        setShowSubmitBtm(true);
                                      }}
                                    >
                                      <DeleteOutlined />
                                    </div>
                                  </div>
                                </div>
                              ))}
                              <div className="mt-1">
                                <Dragger
                                  beforeUpload={beforeUpload}
                                  fileList={[]}
                                  onChange={handleAttachmentChange}
                                >
                                  <a onClick={() => { }} className="addNewItem">
                                    <Icon name="plus-round" />
                                    <span>Add Attachment</span>
                                  </a>
                                </Dragger>
                              </div>
                            </div>
                          </div>
                        </Descriptions.Item>
                        {/* )} */}
                      </Descriptions>
                    </Panel>
                  </AntCollapse>
                </div>
                {addNewRowModel && (
                  <MaterialModal
                    modal={addNewRowModel}
                    closeModal={() => {
                      setAddNewRowModel(false);
                      setEditLineRow(null);
                    }}
                    // onSubmit={updateBudgetLines}
                    status={materialData?.orderStatus}
                    formData={{
                      action: editLineRow == null ? "add" : "edit",
                      index: editLineRow?.lineId,
                      item: editLineRow,
                    }}
                    appenddata={append}
                    update={(id: any, data: any) => {
                      update(id, data);
                    }}
                    // needByDateType={materialData?.needByDateType}
                    // deliveryAddressType={materialData?.deliveryAddressType}
                    budgetStartDate={materialData?.startDate}
                    budgetEndDate={materialData?.endDate}
                  />
                )}
              </div>
            )}
          </div>
        </Card>
      </Content>
      {/* <div>
        <Button
          className="toggle"
          color="primary"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "-3.7%",
            marginLeft: "80%",
            marginBottom: "1%",
          }}
          onClick={() => submitSave()}
        >
          <Icon name="check" />
          <span>Submit Save</span>
        </Button>
      </div> */}

      {/* <QuotationApprovalDrawer
        open={approvals.open}
        onClose={() => {
          setApprovals((pre) => {
            return {
              ...pre,
              open: false,
            };
          });
        }}
        onSubmit={(data: any) => {
          ApprovalsUpdate(data);
          setApprovals({
            open: false,
            data: data,
          });
        }}
        setApprovalSubmissionStatus={(status: boolean) => {}}
        approvalData={approvals.data} // Pass the current approval data
      /> */}

      <AntModal
        title={undefined}
        visible={instructionsVisible}
        footer={null}
        onCancel={() => {
          setIsInstructionsChanged(false);
          setInstructionsVisible(false);
        }}
        width="60%"
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
      >
        <div className="d-flex">
          <h5 className="m-3">Instructions </h5>
          {!isInstructionsChanged &&
            // ["Pending", "Draft", "REQUESTED"].includes(rfqStatus) && (
            <EditOutlined onClick={() => setIsInstructionsChanged(true)} />
            // )
          }
        </div>
        {isInstructionsChanged ? (
          <ReactQuill
            theme="snow"
            value={newInstructions}
            onChange={(data) => {
              setNewInstructions(data);
            }}
            className="mb-3"
          />
        ) : instructions ? (
          <div
            dangerouslySetInnerHTML={{
              __html: instructions || "",
            }}
            className="m-3"
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Empty description="No Instructions Available for this Purchase Order" />
          </div>
        )}
        {isInstructionsChanged && (
          <ul className="actions clearfix d-flex">
            <li>
              <Button
                color="primary"
                type="submit"
                onClick={() => {
                  setInstructionsVisible(false);
                  setIsInstructionsChanged(true);
                  setShowSubmitBtm(true);
                }}
              >
                Submit
              </Button>
            </li>
            <li className="m-3 mt-0">
              <Button
                color="primary"
                onClick={() => {
                  setIsInstructionsChanged(false);
                  setNewInstructions(instructions);
                }}
                className="btn-dim btn btn-secondary"
              >
                Cancel
              </Button>
            </li>
          </ul>
        )}
      </AntModal>
      <AntModal
        title={undefined}
        visible={termsVisible}
        footer={null}
        onCancel={() => {
          setIsTermsAndConditionsChanged(false);
          setTermsVisible(false);
        }}
        width="60%"
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
      >
        <div className="d-flex">
          <h5 className="m-3">Terms and Conditions</h5>
          {!isTermsAndConditionsChanged &&
            // ["Pending", "Draft", "REQUESTED"].includes(rfqStatus) && (
            <EditOutlined
              onClick={() => setIsTermsAndConditionsChanged(true)}
            />
            // )
          }
        </div>
        {
          // ["Pending", "Draft", "REQUESTED"].includes(rfqStatus) &&
          isTermsAndConditionsChanged ? (
            <ReactQuill
              theme="snow"
              value={newTermsAndConditions}
              onChange={(data) => {
                setNewTermsAndConditions(data);
              }}
              className="mb-3"
            />
          ) : termsAndConditions ? (
            <div
              dangerouslySetInnerHTML={{
                __html: termsAndConditions || "",
              }}
              className="m-3"
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <Empty description="No Terms and Conditions Available for this Purchase Order" />
            </div>
          )}

        {isTermsAndConditionsChanged && (
          <ul className="actions clearfix d-flex">
            <li>
              <Button
                color="primary"
                type="submit"
                onClick={() => {
                  setTermsVisible(false);
                  setIsTermsAndConditionsChanged(true);
                  setShowSubmitBtm(true);
                }}
              >
                Submit
              </Button>
            </li>
            <li className="m-3 mt-0">
              <Button
                color="primary"
                onClick={() => {
                  setIsTermsAndConditionsChanged(false);
                  setNewTermsAndConditions(termsAndConditions);
                }}
                className="btn-dim btn btn-secondary"
              >
                Cancel
              </Button>
            </li>
          </ul>
        )}
      </AntModal>




      <AntModal
        title={actionStatus === "Hold" ? "Hold Budget" : actionStatus ==="Complete"?"Complete Budget":"Inactive Budget"}
        visible={showActionModelVisible}
        onOk={handleOk} // Ensure form is submitted on OK
        onCancel={handleCancel}
        okText="Submit"
        cancelText="Cancel"
      >
        {actionStatus === "Hold" ? (
          <>
            <Form form={form} layout="vertical" name="rejectForm">
              <Form.Item
                name="reason"
                label="Reason for Hold"
                rules={[{ required: true, message: "Please input your reason!" }]}
              >
                <Input.TextArea placeholder="Type your reason here..." />
              </Form.Item>
            </Form>
          </>
        ) : actionStatus === "Complete" ?(
          <Form form={form} layout="vertical" name="completeForm">
            <Form.Item
              name="completionDate"
              label="Completion Date"
              rules={[
                { required: true, message: "Please select the completion date!" },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                onChange={(date) => {
                  setCompletedDateSelectedValue(date);
                  form.validateFields(); // Trigger form validation
                }}
              />
            </Form.Item>

            {/* Show "Reason for Delay" if completionDate is after endDate */}
            {completedDateSelectedValue &&
              materialData?.endDate &&
              dayjs(form.getFieldValue("completionDate")).isAfter(dayjs(materialData?.endDate)) && (
                <Form.Item
                  name="reasonForDelay"
                  label="Reason for Delay"
                  rules={[{ required: true, message: "Please input the reason for delay!" }]}
                >
                  <Input.TextArea placeholder="Type your reason here..." />
                </Form.Item>
              )}
          </Form>
        ) :(
          <>
            <Form form={form} layout="vertical" name="inactiveForm">
              <Form.Item
                name="inActiveReason"
                label="Reason for Inactive"
                rules={[{ required: true, message: "Please input your reason!" }]}
              >
                <Input.TextArea placeholder="Type your reason here..." />
              </Form.Item>
            </Form>
          </>
        )}
      </AntModal>
    </>
  );
};

export default ViewBudgetDetails;