import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  TooltipComponent,
} from "../components/Component";
import { BalanceBarChart, DepositBarChart, WithdrawBarChart } from "../components/partials/charts/invest/InvestChart";
import PRSummaryWidget from "./dashboard/PRSummaryWidget";
import TopPurchaseCategoriesWidget from "./dashboard/TopPurchseCategoriesWidget";
import TopSupplierSavingsWidget from "./dashboard/TopSupplierSavingsWidget";
import BudgetOverRunWidget from "./dashboard/BudgetOverRunWidget";
import P2PWidget from "./dashboard/P2PWidget";
import { formatCurrency2, formatCurrency } from '../utils/Utils';
import { Tooltip } from "antd"; // Import Tooltip from Ant Design
import { InfoCircleOutlined } from '@ant-design/icons'; // Import Info Icon from Ant Design
import dayjs, { Dayjs } from 'dayjs';
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { Select, DatePicker,Flex, Spin } from "antd";
import moment, { Moment } from "moment";
import mainDashboardApis from "../api/dashboard/MainDashboard";
import Swal from "sweetalert2";
import { set } from "date-fns";

const { Option } = Select;
const { RangePicker } = DatePicker;


const InvestHomePage = () => {
  const { data: currentUser, loading, error } = useSelector(state => state.currentUser);
  const [sm, updateSm] = useState(false);
  const [site, setSite] = useState("");
  const [siteOptions, setSiteOptions] = useState([]);

  const [dateRange, setDateRange] = useState("Month to Date");
  const [customDateRange, setCustomDateRange] = useState();

  const [topWidgetsData, setTopWidgetsData] = useState();
  const [topPurchaseCategoriesData, setTopPurchaseCategoriesData] = useState();
  const [procureToPayCycleData, setProcureToPayCycleData] = useState();
  const [purchaseRequestSummaryData, setPurchaseRequestSummaryData] = useState();
  const [loadingCount, setLoadingCount] = useState(4);
  const convertToDates = (dateType) => {
    switch (dateType) {
      case "Month to Date":
        return {
          from: dayjs().startOf("month").format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        };
      case "Quarter to Date":
        return {
          from: dayjs()
            .subtract(3, "month")
            .startOf("month")
            .format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        };
      case "Year to Date":
        return {
          from: dayjs().startOf("year").format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        };
      default:
        return {
          from: dayjs(customDateRange[0]).format("YYYY-MM-DD"),
          to: dayjs(customDateRange[1]).format("YYYY-MM-DD"),
        };
    }
  };

  useEffect(() => {
    if (currentUser) {
      const { siteAccessibility } = currentUser;
      if (siteAccessibility) {
        const allSites = [];
        const _siteOptions = [];
        siteAccessibility?.forEach((item) => {
          allSites.push(item.siteID);
          _siteOptions.push({ label: item.siteName, value: item.siteID });
        });
        setSite(
          allSites.join(",")
        );
        setSiteOptions([
          {
            label: "All Sites",
            value: allSites?.join(","),
          },
          ..._siteOptions,
        ]);
      }
    }
  }, [currentUser]);

  const handleSiteChange = (value) => {
    setSite(value.toString());
  };

  const handleDateRangeChange = (value) => {
    setDateRange(value);
    if (value !== "Custom Date") {
      setCustomDateRange(null);
    }
  };

  const handleCustomDateChange = (dates) => {
    if (dates && dates[0] && dates[1]) {
      setCustomDateRange([moment(dates[0].toDate()), moment(dates[1].toDate())]);
    } else {
      setCustomDateRange(null);
    }
  };

  useEffect(() => {
    if (site) {
      setLoadingCount(4);
      fetchTopWidgetsResponse();
      fetchTopPurchaseCategories();
      fetchPurchaseRequestSummary();
      fetchProcureToPayCycle();
    }
    else(
      setLoadingCount(0)
    )
  }, [site, dateRange, customDateRange]);

  const fetchTopWidgetsResponse = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const formData = {
        siteId: site,
        status: [
          "Accepted"
        ],
        invoiceType: [
          "commercial"
        ],
        startDate: from,
        endDate: to,
      }
      const res =
        await mainDashboardApis.topWidgetsResponse(formData);
      const { response, status,message } = res;
      console.log(response);
      if (status) {
        setTopWidgetsData(response);
        setLoadingCount((pre) => --pre);
      }
      else {
        Swal.fire("Error", message, "error");
      }
      // setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchTopPurchaseCategories = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const formData = {
        siteId: site,
        startDate: from,
        endDate: to,
      }
      const res =
        await mainDashboardApis.topPurchaseCategories(formData);
      const { response, status,message } = res;
      console.log(response);
      if (status) {
        setTopPurchaseCategoriesData(response);
        setLoadingCount((pre) => --pre);
      }
      else {
        Swal.fire("Error", message, "error");
      }
      // setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }


  const fetchProcureToPayCycle = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const formData = {
        siteId: site,
        startDate: from,
        endDate: to,
      }
      const res =
        await mainDashboardApis.procureToPayCycle(formData);
      const { response, status,message } = res;
      console.log(response);
      if (status) {
        setProcureToPayCycleData(response);
        setLoadingCount((pre) => --pre);
      }
      else {
        Swal.fire("Error", message, "error");
      }
      // setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchPurchaseRequestSummary = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const formData = {
        siteId: site,
        startDate: from,
        endDate: to,
      }
      const res =
        await mainDashboardApis.purchaseRequestSummary(formData);
      const { response, status,message } = res;
      console.log(response);
      if (status) {
        setPurchaseRequestSummaryData(response);
        setLoadingCount((pre) => --pre);
      }
      else {
        Swal.fire("Error", message, "error");
      }
      // setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <React.Fragment>
      <Head title="Procurex Dashboard" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Dashboard</BlockTitle>
              <BlockDes className="text-soft">
                <p>Welcome to Procurex.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <div style={{ marginRight: 16 }}>
                        <label style={{ fontWeight: "bold", marginRight: 8 }}>
                          Filter by Site:
                        </label>
                        <Select
                          defaultValue="ALL"
                          style={{ width: 150 }}
                          onChange={handleSiteChange}
                        >
                          {siteOptions?.map((item) => (
                            <Option value={item.value}>{item.label}</Option>
                          ))}
                        </Select>
                      </div>
                      <div>
                        <label style={{ fontWeight: 'bold', marginRight: 8 }}>Filter by Date Range:</label>
                        <Select
                          defaultValue="Month to Date"
                          style={{ width: 200, marginRight: 16 }}
                          onChange={handleDateRangeChange}
                        >
                          <Option value="Month to Date">Month to Date</Option>
                          <Option value="Quarter to Date">Quarter to Date</Option>
                          <Option value="Year to Date">Year to Date</Option>
                          <Option value="Custom Date">Custom Date</Option>
                        </Select>
                        {dateRange === "Custom Date" && (
                          <RangePicker
                            value={
                              customDateRange
                                ? [dayjs(customDateRange[0].toDate()), dayjs(customDateRange[1].toDate())]
                                : null
                            }
                            onChange={(dates, dateStrings) => handleCustomDateChange(dates)}
                            style={{ width: 300 }}
                          />
                        )}
                      </div>
                    </li>
                    {/* <li>
                      <Button color="primary" outline className="btn-dim btn-white">
                        <Icon name="download-cloud"></Icon>
                        <span>Export</span>
                      </Button>
                    </li> */}
                    {/* <li>
                      <Button color="primary" outline className="btn-dim btn-white">
                        <Icon name="reports"></Icon>
                        <span>Reports</span>
                      </Button>
                    </li> */}
                    {/* <li className="nk-block-tools-opt">
                      <UncontrolledDropdown>
                        <DropdownToggle color="transparent" className="dropdown-toggle btn btn-icon btn-primary">
                          <Icon name="plus"></Icon>
                        </DropdownToggle>
                        <DropdownMenu end>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                href="#adduser"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="user-add-fill"></Icon>
                                <span>Add User</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                href="#addorder"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="coin-alt-fill"></Icon>
                                <span>Add Order</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                href="#addpage"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="note-add-fill-c"></Icon>
                                <span>Add Page</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li> */}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        {loadingCount > 0 ? (
          <>
            <Flex align="center" gap="middle" className="pageLoader">
              <Spin size="large" className="m-auto" />
            </Flex>
          </>
        ) : (
          <Block>
            <Row className="g-gs">
              <Col md="4">
                <PreviewAltCard className="card-full">
                  <div className="card-title-group align-start mb-0">
                    <div className="card-title">
                      <h6 className="subtitle">Total Purchase Orders</h6>
                    </div>
                    <div className="card-tools">
                      <TooltipComponent
                        iconClass="card-hint"
                        icon="help-fill"
                        direction="left"
                        id="invest-deposit"
                        text="Total Purchase Orders"
                      ></TooltipComponent>
                    </div>
                  </div>
                  <div className="card-amount">
                    <span className="amount">
                      {formatCurrency2(topWidgetsData?.totalPurchaseOrders?.total_order_value||0, 'INR')}
                      <Tooltip title={formatCurrency(topWidgetsData?.totalPurchaseOrders?.total_order_value, 'INR')}>
                        <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                      </Tooltip>
                    </span>
                  </div>
                  <div className="invest-data">
                    <div className="invest-data-amount g-2">
                      <div className="invest-data-history">
                        <div className="title">This Month</div>
                        <span className="amount">
                          {formatCurrency2(topWidgetsData?.totalPurchaseOrders?.this_monthOrder||0, 'INR')}
                          <Tooltip title={formatCurrency(topWidgetsData?.totalPurchaseOrders?.this_monthOrder, 'INR')}>
                            <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                          </Tooltip>
                        </span>
                      </div>
                      <div className="invest-data-history">
                        <div className="title">This Week</div>
                        <span className="amount">
                          {formatCurrency2(topWidgetsData?.totalPurchaseOrders?.this_weekOrder||0, 'INR')}
                          <Tooltip title={formatCurrency(topWidgetsData?.totalPurchaseOrders?.this_weekOrder, 'INR')}>
                            <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                          </Tooltip>
                        </span>
                      </div>
                    </div>
                    <div className="invest-data-ck">
                      <DepositBarChart totalPurchaseOrdersData={topWidgetsData?.totalPurchaseOrders?.data} />
                    </div>
                  </div>
                </PreviewAltCard>
              </Col>

              <Col md="4">
                <PreviewAltCard className="card-full">
                  <div className="card-title-group align-start mb-0">
                    <div className="card-title">
                      <h6 className="subtitle">Total Orders Received</h6>
                    </div>
                    <div className="card-tools">
                      <TooltipComponent
                        iconClass="card-hint"
                        icon="help-fill"
                        direction="left"
                        id="invest-withdraw"
                        text="Total Orders Received"
                      ></TooltipComponent>
                    </div>
                  </div>
                  <div className="card-amount">
                    <span className="amount">
                      {formatCurrency2(topWidgetsData?.totalOrdersReceived?.total_invoice_value||0, 'INR')}
                      <Tooltip title={formatCurrency(topWidgetsData?.totalOrdersReceived?.total_invoice_value, 'INR')}>
                        <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                      </Tooltip>
                    </span>
                  </div>
                  <div className="invest-data">
                    <div className="invest-data-amount g-2">
                      <div className="invest-data-history">
                        <div className="title">This Month</div>
                        <div className="amount">
                          {formatCurrency2(topWidgetsData?.totalOrdersReceived?.this_monthinvoice||0, 'INR')}
                          <Tooltip title={formatCurrency(topWidgetsData?.totalOrdersReceived?.this_monthinvoice, 'INR')}>
                            <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                          </Tooltip>
                        </div>
                      </div>
                      <div className="invest-data-history">
                        <div className="title">This Week</div>
                        <div className="amount">
                          {formatCurrency2(topWidgetsData?.totalOrdersReceived?.this_weekinvoice||0, 'INR')}
                          <Tooltip title={formatCurrency(topWidgetsData?.totalOrdersReceived?.this_weekinvoice, 'INR')}>
                            <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <div className="invest-data-ck">
                      <WithdrawBarChart withdrawBarChartData={topWidgetsData?.totalOrdersReceived?.data} />
                    </div>
                  </div>
                </PreviewAltCard>
              </Col>

              <Col md="4">
                <PreviewAltCard className="card-full">
                  <div className="card-title-group align-start mb-0">
                    <div className="card-title">
                      <h6 className="subtitle">Payments</h6>
                    </div>
                    <div className="card-tools">
                      <TooltipComponent
                        iconClass="card-hint"
                        icon="help-fill"
                        direction="left"
                        id="invest-balance"
                        text="Total amount paid"
                      ></TooltipComponent>
                    </div>
                  </div>
                  <div className="card-amount">
                    <span className="amount">

                      {formatCurrency2(topWidgetsData?.paymentsWidget?.total_payable_value||0, 'INR')}
                      <Tooltip title={formatCurrency(topWidgetsData?.paymentsWidget?.total_payable_value, 'INR')}>
                        <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                      </Tooltip>
                    </span>
                  </div>
                  <div className="invest-data">
                    <div className="invest-data-amount g-2">
                      <div className="invest-data-history">
                        <div className="title">This Month</div>
                        <div className="amount">
                          {/* 2,940.59 <span className="currency currency-usd">INR</span> */}
                          {formatCurrency2(topWidgetsData?.paymentsWidget?.this_monthPayable||0, 'INR')}
                          <Tooltip title={formatCurrency(topWidgetsData?.paymentsWidget?.this_monthPayable, 'INR')}>
                            <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                          </Tooltip>
                        </div>
                      </div>
                      <div className="invest-data-history">
                        <div className="title">This Week</div>
                        <div className="amount">
                          {/* 1,259.28 <span className="currency currency-usd">INR</span> */}
                          {formatCurrency2(topWidgetsData?.paymentsWidget?.this_weekPayable||0, 'INR')}
                          <Tooltip title={formatCurrency(topWidgetsData?.paymentsWidget?.this_weekPayable, 'INR')}>
                            <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <div className="invest-data-ck">
                      <BalanceBarChart balanceBarChartData={topWidgetsData?.paymentsWidget?.data} />
                    </div>
                  </div>
                </PreviewAltCard>
              </Col>

              {/* Rest of the widgets */}
              <Col md="6">
                <PreviewAltCard className="card-full">
                  <PRSummaryWidget prSummaryData={purchaseRequestSummaryData}></PRSummaryWidget>
                </PreviewAltCard>
              </Col>

              <Col md="6">
                <PreviewAltCard className="card-full">
                  <TopPurchaseCategoriesWidget topPurchaseCategoriesData={topPurchaseCategoriesData}></TopPurchaseCategoriesWidget>
                </PreviewAltCard>
              </Col>

              <Col md="6">
                <PreviewAltCard className="card-full">
                  <TopSupplierSavingsWidget dates={convertToDates(dateRange)} sites={site}></TopSupplierSavingsWidget>
                </PreviewAltCard>
              </Col>
              <Col md="6">
                <PreviewAltCard className="card-full">
                  <BudgetOverRunWidget dates={convertToDates(dateRange)} sites={site}></BudgetOverRunWidget>
                </PreviewAltCard>
              </Col>
              <Col xl="12">
                <PreviewAltCard className="card-full" >
                  <P2PWidget procureToPayCycleData={procureToPayCycleData}></P2PWidget>
                </PreviewAltCard>
              </Col>
            </Row>
          </Block>
        )}
      </Content>
    </React.Fragment>
  );
};

export default InvestHomePage;
