import React, { useEffect, useState } from 'react';
import quotationApis from '../../../../api/master/quotations';
import dayjs from 'dayjs';
import { Button, Col, Row } from 'reactstrap';
import { InfoCircleOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { Option } from "antd/es/mentions";
import {
    PaperClipOutlined,
    DownloadOutlined,
    PlusCircleOutlined,
    MinusCircleOutlined,
    DeleteOutlined,
    UploadOutlined,
    PlusSquareOutlined,
    EditOutlined,
} from "@ant-design/icons";
import {
    Form,
    Input,
    InputNumber,
    Table,
    Collapse as AntCollapse,
    Descriptions,
    Modal as AntModal,
    Spin,
    Flex,
    Drawer,
    Upload,
    Select,
    Popover,
    DatePicker,
} from "antd";
import { formatCurrency } from '../../../../utils/Utils';
import Misc from '../../../masters/Misc';
import { nanoid } from 'nanoid';
import { Icon } from '../../../../components/Component';
import { useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import SignatureModal from '../../../purchase-orders/SignatureModal';
import CropperComponent from '../../../components/CropperComponent';

const { Panel } = AntCollapse;


const { beforeUpload } = Misc;


interface CurrencyData {
    description: string;
    currency_code: string;
    currency_symbol: string;
    currency_format: string;
    example: string;
}
interface AddSupplierQuoteDrawer {
    supplierQuoteData: {
        materials: any;
    };
    visible: boolean;
    onClose: () => void;
    onSubmit: (values: any) => void;
}

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}: any) => {
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            validator: async (_: any, value: any) => {
                                // Special case for IGST: it can be 0
                                if (dataIndex === 'cgst' || dataIndex === 'igst' || dataIndex === 'sgst' && value === 0) {
                                    return Promise.resolve();
                                }
                                if (dataIndex === "minQty" && value === 0) {
                                    return Promise.resolve();
                                }
                                // For all other fields, ensure the value is greater than 0
                                if (!value || value <= 0) {
                                    return Promise.reject(new Error(`${title} must be greater than 0`));
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <InputNumber
                        className="w-100"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                        min={
                            dataIndex === 'cgst' || dataIndex === 'igst' || dataIndex === 'sgst'
                                ? 0 // Allow decimal values for tax fields
                                : 1 // Enforce integer values for supplyQuantity, minPurchaseQuantity, and leadDays
                        }
                        step={
                            dataIndex === 'cgst' || dataIndex === 'igst' || dataIndex === 'sgst' || dataIndex === 'price' || dataIndex === 'minQty'
                                ? 0.01 // Allow decimals for tax fields
                                : 1 // Force integer input for supplyQuantity, minPurchaseQuantity, leadDays
                        }
                        onKeyPress={(event) => {
                            if (dataIndex === 'cgst' || dataIndex === 'igst' || dataIndex === 'sgst' || dataIndex === 'price' || dataIndex === 'minQty') {
                                // Allow numbers and decimal point for tax fields
                                if (!/^[0-9.]+$/.test(event.key)) {
                                    event.preventDefault();
                                }
                            } else {
                                // Allow only numbers (no decimals) for other fields
                                if (!/^[0-9]+$/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }
                        }}
                    />
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};


const AddSupplierQuoteDrawer: React.FC<AddSupplierQuoteDrawer> = ({ visible, onClose, supplierQuoteData, onSubmit }) => {
    const [form2] = Form.useForm();
    const [form] = Form.useForm();
    const methods = useForm({
        mode: "onChange",
    });

    const {
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors },
    } = methods;
    const [invoiceSignature, setInvoiceSignature] = useState<any>({});
    const [additionalCharges, setAdditionalCharges] = useState<any>([]);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [approvalRequired, setApprovalRequired] = useState("false");
    const [approvals, setApprovals] = useState({
        open: false,
        data: [],
    });
    const [invoiceDate, setInvoiceDate] = useState<any>();
    const [OriginalData, setOriginalData] = useState<any>({});
    const [paymentTerm, setPaymentTerm] = useState<string | undefined>(undefined);
    const [screenSize, setScreenSize] = useState(0);
    const [invoiceableLines, setInvoiceableLines] = useState<any>([]);
    const [editAdditionalCharges, setEditAdditionalCharges] = useState(true);
    const [currency, setCurrency] = useState<CurrencyData>({
        description: "",
        currency_code: "",
        currency_symbol: "",
        currency_format: "",
        example: "",
    });
    const [sm, setSm] = useState(false);
    const [mobileView, setMobileView] = useState(false);
    const [showCropModel, setShowCropModel] = useState<boolean>(false);
    const [showSignatureModel, setShowSignatureModel] = useState<boolean>(false);
    const [supplierTermsAndConditions, setsupplierTermsAndConditions] = useState<any>("");
    const [documentsAttached, setDocumentsAttached] = useState<any>([]);
    const [requiredDOC, setRequiredDOC]: any = useState();



    const [editingKey, setEditingKey] = useState<any | null>(null);


    const [updatedLineItems, setUpdatedLineItems] = useState<any>([]);

    const [editedData, setEditedData] = useState<any[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [data, setData] = useState<any>([]);
    const [showSubmitBtm, setShowSubmitBtm] = useState(false);
    const [addNewRowModel, setAddNewRowModel] = useState<boolean>(false);
    const isEditing = (record: any) => record.key == editingKey;
    const [editLineRow, setEditLineRow] = useState<any>();

    useEffect(() => {
        console.log("supplierQuoteData", supplierQuoteData?.materials)
        setData(supplierQuoteData?.materials);
    }, [supplierQuoteData]);

    const edit = (record: any) => {
        console.log("record", record)
        form.setFieldsValue({
            suppliedQty: Number(record.suppliedQty),
            minQty: Number(record.minQty),
            // hsn: record.hsn,
            price: Number(record.price),
            sgst: Number(record.sgst),
            cgst: Number(record.cgst),
            igst: Number(record.igst),
            leadDays: Number(record.leadDays),
            ...record
        });
        console.log("key", form, record.key)
        setEditingKey(record.key);
    };

    const save = async (key: React.Key) => {
        console.log("Save", key)
        try {
            console.log("Try")
            const row: any = await form.validateFields();
            console.log("row", row)
            const newData: any = [...data];
            console.log("newData", newData)
            const index = newData.findIndex((item: any) => key == item.quotationLineId);
            if (index > -1) {
                setUpdatedLineItems((pre: any) => {
                    if (!pre.includes(key)) {
                        return pre.concat(key);
                    }
                    return pre;
                });
                const item: any = newData[index];
                console.log("row", row)
                const quantityInvoiced = Number(row?.suppliedQty);
                const unitPrice = Number(row.price);
                console.log("quantityInvoiced", quantityInvoiced)
                const basicAmount = quantityInvoiced * unitPrice;
                const sgst = Number(row.sgst);
                const igst = Number(row.igst);
                const cgst = Number(row.cgst);
                const sgstData = (basicAmount * Number(row.sgst)) / 100;
                const igstData = (basicAmount * Number(row.igst)) / 100;
                const cgstData = (basicAmount * Number(row.cgst)) / 100;
                const hsn = row.hsn;
                const leadDays = row.leadDays;
                const minQty = row.minQty;
                const price = row.price;
                const suppliedQty = row.suppliedQty;
                console.log("basicAmount", basicAmount)
                console.log("sgstData", sgstData)
                console.log("igstData", igstData)
                console.log("cgstData", cgstData)
                const lineTotal = basicAmount + sgstData + igstData + cgstData;
                console.log("lineTotal", lineTotal, basicAmount + sgstData + igstData + cgstData)
                const updatedRow = {
                    ...item,
                    quantityInvoiced,
                    unitPrice,
                    basicAmount,
                    sgst,
                    igst,
                    cgst,
                    sgstData,
                    igstData,
                    cgstData,
                    hsn,
                    leadDays,
                    minQty,
                    price,
                    suppliedQty,
                    grossTotal: basicAmount + sgst + igst + cgst,
                    lineTotal
                };
                console.log(index);
                console.log(updatedRow);

                newData.splice(index, 1, { ...updatedRow });
                setData(newData);
                setEditingKey(null);
                setEditedData(
                    newData.filter((item: any) => selectedRowKeys.includes(item.quotationLineId))
                );
            }
            setShowSubmitBtm(true);
        } catch (errInfo) {
            console.log("Validate Failed:", JSON.stringify(errInfo, null, 2));

        }
    };



    const columns = [
        // {
        //     ...Table.EXPAND_COLUMN,
        //     fixed: "left",
        // },
        // {
        //     title: "MATERIAL ID",
        //     dataIndex: "materialId",
        //     key: "materialId",
        //     fixed: "left" as const,
        //     width: 120,
        // },
        // {
        //     title: "CATEGORY",
        //     dataIndex: "categoryName",
        //     key: "categoryName",
        //     fixed: "left" as const,
        //     width: 150,
        // },
        {
            title: "MATERIAL NAME",
            dataIndex: "materialName",
            key: "materialName",
            width: 200,
        },
        // {
        //     title: "DESCRIPTION",
        //     dataIndex: "description",
        //     key: "description",
        //     width: 200,
        // },
        {
            title: "UOM",
            dataIndex: "uomName",
            key: "uomName",
            width: 150,
        },
        {
            title: "QTY",
            dataIndex: "qty",
            key: "qty",
            width: 150,
        },
        {
            title: "Expected Delivery Date",
            dataIndex: "needByDate",
            width: 200,
            render: (text: any, record: any) =>
                `${dayjs(text).format("DD-MMM-YYYY")}`,
        },
        {
            title: "Supply Qty",
            dataIndex: `suppliedQty`,
            key: "suppliedQty",
            width: 120,
            editable: true,
            render: (text: any) => (isNaN(text) ? '0.00' : Number(text).toFixed(2))
        },
        {
            title: "Min Purchase Qty",
            dataIndex: `minQty`,
            key: "minQty",
            width: 150,
            editable: true,
            render: (text: any) => (isNaN(text) ? '0.00' : Number(text).toFixed(2))
        },
        {
            title: "HSN",
            dataIndex: `hsn`,
            key: "hsn",
            width: 120,
            editable: true,
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            width: 150,
            editable: true,
        },
        {
            title: "SGST (%)",
            dataIndex: "sgst",
            key: "sgst",
            width: 200,
            editable: true,
        },
        {
            title: "CGST (%)",
            dataIndex: "cgst",
            key: "cgst",
            width: 200,
            editable: true,
        },
        {
            title: "IGST (%)",
            dataIndex: "igst",
            key: "igst",
            width: 200,
            editable: true,
        },
        {
            title: "Lead Days",
            dataIndex: "leadDays",
            key: "leadDays",
            width: 250,
            editable: true,
        },
        {
            title: "Line Total",
            dataIndex: "lineTotal",
            key: "lineTotal",
            width: 250,
            // render: (text: any, record: any) => FormatCurrency(text, "INR"),
        },
        {
            title: "Actions",
            key: "actions",
            width: 100,
            fixed: "right",
            render: (_: any, record: any) => {
                const editable = isEditing(record);
                console.log("isEditing:::::0000", editable);
                return editable ? (
                    <div className="w-100 justify-content-around">
                        <Button
                            size="sm"
                            color=""
                            onClick={() => save(record.quotationLineId)}
                            style={{ marginRight: 8 }}
                        >
                            <Icon name="save" />
                        </Button>

                        <Button size="sm" color="" onClick={cancel}>
                            <Icon name="cross" />
                        </Button>
                    </div>
                ) : (
                    <Button size="sm" color="" onClick={() => edit(record)}>
                        <Icon name="edit" />
                    </Button>
                );
            },
        },
    ];


    const cancel = () => {
        setEditingKey(null);
    };

    useEffect(() => {
        if (editLineRow) {
            setAddNewRowModel(true);
        }
    }, [editLineRow]);


    const mergedColumns: any = columns.map((col) => {

        if (!col.editable) {
            return col; // Return columns that are not editable
        }

        // Return columns with editable logic
        return {
            ...col,
            onCell: (record: any) => ({
                record,
                inputType: col.dataIndex === "quantity" ? "number" : "text",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });


    const ViewTableTotals = ({ data, additionalCharges, invoice }: any) => {
        let totalNetAmmount = 0,
            cgst = 0,
            sgst = 0,
            igst = 0,
            TotalAmount = 0;
        data?.forEach((item: any) => {
            console.log("item", item)
            const suppliedQty = Number(item?.suppliedQty || 0);
            const price = Number(item?.price || 0);
            console.log("suppliedQty", suppliedQty, price)
            const totalAmount = suppliedQty * price;
            totalNetAmmount += totalAmount; // Accumulate total amount
            cgst += (totalAmount * Number(item?.cgst || 0)) / 100; // Accumulate CGST
            sgst += (totalAmount * Number(item?.sgst || 0)) / 100; // Accumulate SGST
            igst += (totalAmount * Number(item?.igst || 0)) / 100; // Accumulate IGST
        });
        // console.log("Line Total::::", data)
        console.log("total", totalNetAmmount, cgst, sgst, igst)
        TotalAmount = totalNetAmmount + cgst + sgst + igst;
        console.log("TotalAmount",TotalAmount)

        additionalCharges?.forEach((item: any) => {
            console.log("adddd", item)
            TotalAmount = TotalAmount + Number(item.price);
        });
        return (
            <Row
                className={`${invoice && "w-50 mt-2"}`}
                style={{
                    margin: "20px",
                }}
            >
                <Col className={`justify-end`}>
                    <div className={`${invoice ? "w-100" : "w-40"}`}>
                        <div className="d-flex justify-content-between">
                            <div className="w-50 justify-end">
                                <strong>Taxable Amount</strong>
                            </div>
                            <span className="r_align">
                                {formatCurrency(totalNetAmmount, "INR")}
                            </span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="w-50 justify-end">
                                <strong>CGST</strong>
                            </div>
                            <span className="r_align">
                                {formatCurrency(
                                    cgst,
                                    "INR"
                                )}
                            </span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="w-50 justify-end">
                                <strong>SGST</strong>
                            </div>
                            <span className="r_align">
                                {formatCurrency(
                                    sgst,
                                    "INR"
                                )}
                            </span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="w-50 justify-end">
                                <strong>IGST</strong>
                            </div>
                            <span className="r_align">
                                {formatCurrency(
                                    igst,
                                    "INR"
                                )}
                            </span>
                        </div>
                        {additionalCharges?.map((item: any) => {
                            if (!item.price || !item.price) return null;
                            return (
                                <div className="d-flex justify-content-between">
                                    <div className="w-50 justify-end">
                                        <strong>{item.name}</strong>
                                    </div>
                                    <span className="r_align">
                                        {formatCurrency(
                                            item.price,
                                            "INR"
                                        )}
                                    </span>
                                </div>
                            );
                        })}
                        <div
                            className="d-flex justify-content-between"
                            style={{ fontSize: "18px", marginTop: "10px" }}
                        >
                            <div className="w-50 justify-end">
                                <strong>Total</strong>
                            </div>
                            <strong className="r_align">
                                {formatCurrency(
                                    TotalAmount,
                                    "INR"
                                )}
                            </strong>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    };

    const handlePaymentTermChange = (value: string) => {
        setPaymentTerm(value);
    };


    const uploadProps = {
        beforeUpload: (file: any) => {
            // Perform any file validation or processing here
            return false; // Return false to prevent auto-upload
        },
        maxCount: 1,
    };


    const createInvoice = async (Data: any) => {

    }

    const handleChangeAttachment = (info: any) => {
        let files = info.fileList.slice(-3); // Limit to 3 files
        setDocumentsAttached(files);
    };


    const viewChange = () => {
        setScreenSize(window.innerWidth);
        if (window.innerWidth < 990) {
            setMobileView(true);
        } else {
            setMobileView(false);
            setSm(false);
        }
    };

    useEffect(() => {
        // fetchCurrentUserInfo();
        // fetchOrderDetails();
        viewChange();
        window.addEventListener("load", viewChange);
        window.addEventListener("resize", viewChange);
        const headerClick = () => setSm(false);
        document
            .getElementsByClassName("nk-header")[0]
            ?.addEventListener("click", headerClick);

        return () => {
            window.removeEventListener("resize", viewChange);
            window.removeEventListener("load", viewChange);
            document
                .getElementsByClassName("nk-header")[0]
                ?.removeEventListener("click", headerClick);
        };
    }, []);

    return (
        <>

            <Drawer
                title={
                    <div className="d-flex justify-content-between align-items-center">
                        <strong>Create Quotation</strong>
                        <div style={{ textAlign: "right" }}>
                            <Button
                                onClick={() => {
                                    form2.submit();
                                }}
                                color="primary"
                                className="m-3 mt-0 mb-0"
                            >
                                Create Quotation
                            </Button>
                        </div>
                    </div>
                }
                onClose={onClose}
                visible={visible}
                width={"85vw"}
            >
                <Form
                    initialValues={{
                        approvals: "N", // Set the default value here
                    }}
                    form={form2}
                    layout="vertical"
                    onFinish={(data) => {
                        if (!invoiceSignature?.image) {
                            Swal.fire({
                                icon: "info",
                                title: "Signature",
                                text: "Signature is required",
                            });
                            return;
                        }
                        Swal.fire({
                            title: "Are you sure?",
                            text: "You are about to create!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, Create!",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setDisableSubmit(true);
                                createInvoice(data);
                            }
                        });
                    }}
                    requiredMark={false}
                >
                    <AntCollapse defaultActiveKey={["1", "2"]} className="mt-3">
                        <Panel
                            header={<h6>Supplier Information</h6>}
                            key="1"
                        >
                            <div className="justify-content-around ">
                                <div>
                                    <div className="justify-start w-100  flex-wrap pt-3">
                                        <Form.Item
                                            name="supplierID"
                                            label={
                                                <div className="data-label w-90 mb-1">
                                                    Supplier
                                                </div>
                                            }
                                            className="w-30 p-3 paddingLeft10  pt-0 pb-0"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select Supplier",
                                                },
                                            ]}
                                        >
                                            <Select placeholder="Select">
                                                {/* <Option value="dc">Delivery Challan</Option> */}
                                                <Option value="1">Supplier 1</Option>
                                                <Option value="1">Supplier 2</Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            name="invoiceDate"
                                            label={
                                                <div className="data-label w-90 mb-1">
                                                    Validity Date
                                                </div>
                                            }
                                            className="w-30 p-3 paddingLeft10  pt-0 pb-0"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select Validity Date",
                                                },
                                            ]}
                                        >
                                            <DatePicker
                                                className="w-100"
                                                placeholder="Validity Date"
                                                minDate={dayjs().add(0, "day")}
                                                onChange={(e) => {
                                                    setInvoiceDate(e);
                                                }}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name="paymentTerms"
                                            label={
                                                <div className="data-label w-100 mb-1">
                                                    Payment Terms
                                                </div>
                                            }
                                            className="w-30 p-2 paddingLeft10  pt-0 pb-0"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select Payment Terms",
                                                },
                                            ]}
                                        >
                                            <Select
                                                options={[
                                                    {
                                                        label: "Cash & Carry",
                                                        value: "Cash and Carry",
                                                    },
                                                    { label: "Advance", value: "Advance" },
                                                    { label: "Credit", value: "Credit" },
                                                ]}
                                                placeholder="Select Payment Terms"
                                                onChange={handlePaymentTermChange}
                                            />
                                        </Form.Item>

                                        {paymentTerm === "Advance" && (
                                            <>
                                                <Form.Item
                                                    name="advancePercentage"
                                                    label={
                                                        <div className="data-label w-100 mb-1">
                                                            Advance Percentage
                                                        </div>
                                                    }
                                                    className="w-30 p-2 paddingLeft10  pt-0 pb-0"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Please select Advance Percentage",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        className="w-100"
                                                        type="number"
                                                        placeholder="Enter Advance Percentage"
                                                    // pattern={/^(0|[1-9][0-9]?|100)$/}
                                                    />
                                                </Form.Item>
                                            </>
                                        )}
                                        {paymentTerm === "Credit" && (
                                            <Form.Item
                                                name="paymentDays"
                                                label={
                                                    <div className="data-label w-100 mb-1">
                                                        Credit Days
                                                    </div>
                                                }
                                                className="w-30 p-2 paddingLeft10  pt-0 pb-0"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select Credit Days",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className="w-100"
                                                    type="number"
                                                    placeholder="Enter Credit Days"
                                                />
                                            </Form.Item>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Panel>

                        <Panel header={<h6>Material Information</h6>} key="2">
                            <div className="table-responsive poTable">
                                <div
                                    style={{
                                        width: screenSize - 350 + "px",
                                        margin: "0px auto 15px auto",
                                    }}
                                >
                                    <Form form={form} component={false}>
                                        <Table
                                            // columns={columns}
                                            columns={mergedColumns.map((col: any) => {
                                                return col;
                                            })}
                                            dataSource={data?.map((item: any) => ({
                                                ...item,
                                                key: item.quotationLineId,
                                            }))}
                                            scroll={{ x: 1000 }}
                                            components={{
                                                body: {
                                                    cell: EditableCell,
                                                },
                                            }}
                                            bordered
                                            size="middle"
                                            pagination={false}
                                        />
                                    </Form>
                                </div>
                            </div>

                            <div className="d-flex">
                          <Row className="mt-2 w-50">
                            <div>
                              <div className="d-flex align-items-center">
                                <h6 className="m-1 mb-0">
                                  Additional Charges (If Any)
                                </h6>
                                <div className="w-20 m-1 mb-0">
                                  {editAdditionalCharges ? (
                                    <a
                                      type={undefined}
                                      onClick={() => {
                                        if (
                                          additionalCharges.find(
                                            (field: any, ind: number) => {
                                              const name = form2.getFieldValue(
                                                `AdditionalCharges.${field?.id}.name`
                                              );
                                              const price = form2.getFieldValue(
                                                `AdditionalCharges.${field?.id}.price`
                                              );
                                              return !name || !price;
                                            }
                                          )
                                        ) {
                                          return;
                                        }

                                        setAdditionalCharges([
                                          ...additionalCharges,
                                          {
                                            name: "",
                                            price: null,
                                            id: nanoid(4),
                                          },
                                        ]);
                                      }}
                                      className="addNewItem"
                                    >
                                      <PlusSquareOutlined />
                                    </a>
                                  ) : (
                                    <a
                                      type={undefined}
                                      onClick={() => {
                                        setEditAdditionalCharges(true);
                                      }}
                                      className="addNewItem"
                                    >
                                      <EditOutlined />
                                    </a>
                                  )}
                                </div>
                              </div>
                              {additionalCharges.map(
                                (field: any, index: number) => (
                                  <Row
                                    key={field.id}
                                    className="m-2 mb-0 w-100"
                                  >
                                    <div className="w-50">
                                      {editAdditionalCharges ? (
                                        <Form.Item
                                          name={`AdditionalCharges.${field.id}.name`}
                                          label={
                                            <strong>
                                              Additional Charge Name
                                            </strong>
                                          }
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Charge Name is required",
                                            },
                                          ]}
                                          className="mb-0"
                                        >
                                          <Input
                                            className="w-100"
                                            disabled={!editAdditionalCharges}
                                            autoComplete="off"
                                          />
                                        </Form.Item>
                                      ) : (
                                        <strong>{field.name}</strong>
                                      )}
                                    </div>
                                    <div className="w-30">
                                      {editAdditionalCharges ? (
                                        <Form.Item
                                          name={`AdditionalCharges.${field.id}.price`}
                                          label={<strong>Price (INR)</strong>}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Price is required",
                                            },
                                          ]}
                                          className="mb-0"
                                        >
                                          <InputNumber
                                            min={0}
                                            step={0.01}
                                            disabled={!editAdditionalCharges}
                                            className="w-100"
                                            formatter={(value) =>
                                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") // Adds commas for thousands
                                            }
                                            parser={(value: any) =>
                                              value!.replace(/\$\s?|(,*)/g, "") // Removes commas and symbols
                                            }
                                            onKeyPress={(event) => {
                                              if (!/^[0-9.]$/.test(event.key)) {
                                                event.preventDefault(); // Prevent non-numeric and non-decimal input
                                              }
                                            }}
                                            autoComplete="off"
                                          />
                                        </Form.Item>
                                      ) : (
                                        <strong>
                                          {formatCurrency(
                                            field.price,"INR"
                                          )}
                                        </strong>
                                      )}
                                    </div>
                                    <Col className="d-flex align-items-end w-15">
                                      {editAdditionalCharges && (
                                        <a
                                          href="#dropdownitem"
                                          onClick={() => {
                                            form2.setFieldsValue({
                                              [`AdditionalCharges.${field.id}.name`]:
                                                "",
                                              [`AdditionalCharges.${field.id}.price`]:
                                                null,
                                            });
                                            const newAdditionalCharges: any =
                                              [];
                                            additionalCharges.forEach(
                                              (fld: any, ind: number) => {
                                                if (fld.id != field.id) {
                                                  const name =
                                                    form2.getFieldValue(
                                                      `AdditionalCharges.${fld.id}.name`
                                                    );
                                                  const price =
                                                    form2.getFieldValue(
                                                      `AdditionalCharges.${fld.id}.price`
                                                    );
                                                  if (name && price) {
                                                    newAdditionalCharges.push({
                                                      name,
                                                      price,
                                                      id: fld.id,
                                                    });
                                                  }
                                                }
                                              }
                                            );
                                            setAdditionalCharges(
                                              newAdditionalCharges
                                            );
                                          }}
                                          className="addNewItem"
                                        >
                                          <DeleteOutlined />
                                        </a>
                                      )}
                                    </Col>
                                  </Row>
                                )
                              )}
                              {additionalCharges.length > 0 &&
                                editAdditionalCharges && (
                                  <Button
                                    className="m-4 mt-3 mb-0"
                                    color="primary"
                                    onClick={() => {
                                      const newAdditionalCharges: any = [];
                                      additionalCharges.forEach(
                                        (field: any, index: number) => {
                                          const name = form2.getFieldValue(
                                            `AdditionalCharges.${field?.id}.name`
                                          );
                                          const price = form2.getFieldValue(
                                            `AdditionalCharges.${field?.id}.price`
                                          );
                                          if (name && price) {
                                            newAdditionalCharges.push({
                                              name,
                                              price,
                                              id: field?.id,
                                            });
                                          }
                                        }
                                      );
                                      setAdditionalCharges(() => {
                                        return newAdditionalCharges;
                                      });
                                      if (newAdditionalCharges.length != 0) {
                                        setEditAdditionalCharges(false);
                                      }
                                    }}
                                  >
                                    Save
                                  </Button>
                                )}
                            </div>
                          </Row>

                          <ViewTableTotals
                                    data={data}
                                    additionalCharges={additionalCharges}
                                    invoice={true}
                                />
                        </div>
                        <Row className="m-4 justify-content-end ">
                          {invoiceSignature?.isActive ? (
                            <div className="signatureBtnWrapper">
                              <div className="d-flex justify-content-between align-items-center">
                                <strong>Signature</strong>
                                <a
                                  style={{
                                    fontSize: "14px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setInvoiceSignature((prev: any) => ({
                                      ...prev,
                                      isActive: false,
                                      image: null,
                                      label: "Authorised Signatory",
                                    }));
                                    setValue("signatureObj", {
                                      isActive: false,
                                      image: null,
                                      label: "Authorised Signatory",
                                    });
                                  }}
                                >
                                  <Icon
                                    name="cross"
                                    className="pointer"
                                    onClick={() => {
                                      setInvoiceSignature((prev: any) => ({
                                        ...prev,
                                        isActive: false,
                                        image: null,
                                        label: "Authorised Signatory",
                                      }));
                                      setValue("signatureObj", {
                                        isActive: false,
                                        image: null,
                                        label: "Authorised Signatory",
                                      });
                                    }}
                                  />
                                </a>
                              </div>
                              <div
                                style={{
                                  minHeight: invoiceSignature?.image
                                    ? "0px"
                                    : "120px",
                                  display: invoiceSignature?.image
                                    ? "block"
                                    : "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {!invoiceSignature?.image && (
                                  <Button
                                    color="btn-dim btn"
                                    onClick={() => {
                                      setShowCropModel(true);
                                    }}
                                  >
                                    <Icon
                                      name="upload-cloud"
                                      className="pointer"
                                      onClick={() => {
                                        setShowCropModel(true);
                                      }}
                                    />
                                    <span>Upload</span>
                                  </Button>
                                )}
                                {invoiceSignature?.image && (
                                  <>
                                    <div className="signatueImgWrapper border">
                                      <img
                                        src={invoiceSignature?.image}
                                        alt="Signed"
                                        style={{ width: "160px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <Button
                                        color="btn-dim btn"
                                        onClick={() => {
                                          setInvoiceSignature((pre: any) => {
                                            return { ...pre, image: null };
                                          });
                                        }}
                                      >
                                        <span>Reset</span>
                                        <Icon
                                          name="redo"
                                          className="pointer"
                                          onClick={() => {
                                            setInvoiceSignature((pre: any) => {
                                              return { ...pre, image: null };
                                            });
                                          }}
                                        />
                                      </Button>
                                    </div>
                                    <div>
                                      <div className="form-group">
                                        <label
                                          className="form-label"
                                          htmlFor="signatureLabel"
                                        >
                                          Signature Label
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="signatureLabel"
                                          onChange={(e) => {
                                            setInvoiceSignature((pre: any) => {
                                              return {
                                                ...pre,
                                                label: e.target.value,
                                              };
                                            });
                                          }}
                                          defaultValue={
                                            invoiceSignature?.label ||
                                            "Authorised Signatory"
                                          }
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>

                              {!invoiceSignature?.image && (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Button
                                      color="btn-dim btn"
                                      onClick={() =>
                                        setShowSignatureModel(true)
                                      }
                                    >
                                      <Icon name="pen" />
                                      <span>Use Signature Pad</span>
                                    </Button>
                                  </div>
                                </>
                              )}
                            </div>
                          ) : (
                            <div
                              style={{
                                border: "1px solid #e5ecf7",
                                backgroundColor: "#f7faff",
                                padding: "10px",
                                marginLeft: "10px",
                                width: "22%",
                                borderRadius: "5px",
                                textAlign: "center",
                              }}
                            >
                              <Button
                                color="btn-dim btn"
                                onClick={() => {
                                  setInvoiceSignature((pre: any) => {
                                    return { ...pre, isActive: true };
                                  });
                                }}
                              >
                                <Icon
                                  name="edit"
                                  className="pointer"
                                  onClick={() => {
                                    setInvoiceSignature((pre: any) => {
                                      return { ...pre, isActive: true };
                                    });
                                  }}
                                />
                                <span>Add Signature</span>
                              </Button>
                            </div>
                          )}
                        </Row>

                        </Panel>
                        <Panel header={<h6>Terms and Conditions</h6>} key="3">
                            <div>
                                <ReactQuill
                                    theme="snow"
                                    value={supplierTermsAndConditions}
                                    onChange={(data) => {
                                        setsupplierTermsAndConditions(data);
                                    }}
                                    className="mb-3"
                                />
                            </div>
                        </Panel>
                        <Panel header={<h6>Attachments (If Any)</h6>} key="4">
                            <div className="w-100 d-flex m-2">
                                <div className="w-25">
                                    <Form.Item
                                        name="attachDC"
                                        label={<strong>Attach Documents</strong>}
                                        valuePropName="fileList"
                                        getValueFromEvent={(e: any) => e?.fileList}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please upload at least one document.",
                                            },
                                        ]}
                                    >
                                        <Upload
                                            beforeUpload={beforeUpload}
                                            onChange={handleChangeAttachment}
                                            multiple={true}
                                            fileList={documentsAttached}
                                            accept=".pdf,.jpg,.jpeg,.png"
                                            className="w-100"
                                        >
                                            <Button icon={<UploadOutlined />}>
                                                Click to Upload
                                            </Button>
                                        </Upload>
                                    </Form.Item>
                                </div>
                                {/* Debugging Output */}
                                {/* {JSON.stringify(requiredDOC)} */}

                                {/* Dynamic Form Items */}
                                {requiredDOC && requiredDOC.length > 0 && (
                                    requiredDOC.map((doc: any) => (
                                        <div className="w-25">
                                            <Form.Item
                                                key={doc.quotationDocNameId}
                                                name={`doc_${doc.quotationDocNameId}`}
                                                label={<strong>{doc.documentName}</strong>}
                                                valuePropName="fileList"
                                                getValueFromEvent={(e: any) =>
                                                    Array.isArray(e) ? e : e?.fileList
                                                }
                                                rules={[
                                                    {
                                                        required: doc.mandatory == 1,
                                                        message: `${doc.documentName} is required`,
                                                    },
                                                ]}
                                            >
                                                <Upload {...uploadProps} className="w-100">
                                                    <Button icon={<UploadOutlined />}>
                                                        Click to Upload
                                                    </Button>
                                                </Upload>
                                            </Form.Item>
                                        </div>
                                    ))
                                )}
                                {/* </div> */}
                            </div>
                        </Panel>
                    </AntCollapse>
                    {showCropModel && (
                        <CropperComponent
                            modal={showCropModel}
                            toggle={() => setShowCropModel(!showCropModel)}
                            onSave={(data: string) => {
                                setInvoiceSignature((pre: any) => {
                                    return { ...pre, image: data };
                                });
                                setShowCropModel(false);
                            }}
                        />
                    )}
                    {showSignatureModel && (
                        <SignatureModal
                            modal={showSignatureModel}
                            toggle={() =>
                                setShowSignatureModel(!showSignatureModel)
                            }
                            onSave={(data: string) => {
                                setInvoiceSignature((pre: any) => {
                                    return { ...pre, image: data };
                                });
                                setShowSignatureModel(false);
                            }}
                        />
                    )}
                </Form>
            </Drawer>

        </>
    );
};

export default AddSupplierQuoteDrawer;
