import React, { useEffect, useLayoutEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Invest from "../pages/Invest";
import Faq from "../pages/others/Faq";
import Regularv1 from "../pages/others/Regular-1";
import Regularv2 from "../pages/others/Regular-2";
import Terms from "../pages/others/Terms";
import UserListRegular from "../pages/pre-built/user-manage/UserListRegular";
import UserContactCard from "../pages/pre-built/user-manage/UserContactCard";
import UserDetails from "../pages/pre-built/user-manage/UserDetailsRegular";
import UserListCompact from "../pages/pre-built/user-manage/UserListCompact";
import UserProfileRegular from "../pages/pre-built/user-manage/UserProfileRegular";
import UserProfileSetting from "../pages/pre-built/user-manage/UserProfileSetting";
import UserProfileNotification from "../pages/pre-built/user-manage/UserProfileNotification";
import UserProfileActivity from "../pages/pre-built/user-manage/UserProfileActivity";
import KycListRegular from "../pages/pre-built/kyc-list-regular/KycListRegular";
import KycDetailsRegular from "../pages/pre-built/kyc-list-regular/kycDetailsRegular";
import TransListBasic from "../pages/pre-built/trans-list/TransListBasic";
import TransListCrypto from "../pages/pre-built/trans-list/TransListCrypto";
import ProductCard from "../pages/pre-built/products/ProductCard";
import ProductList from "../pages/pre-built/products/ProductList";
import ProductDetails from "../pages/pre-built/products/ProductDetails";

import InvoicePrint from "../pages/pre-built/invoice/InvoicePrint";

import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";

import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Success from "../pages/auth/Success";

import Layout from "../layout/Index";
import LayoutNoSidebar from "../layout/Index-nosidebar";

//Procurex Pages

import AddNewPurchaseRequest from "../pages/purchase-requests/AddNewPurchaseRequest";
import ViewPurchaseRequests from "../pages/purchase-requests/ViewPurchaseRequests";
import PurchaseRequestAprrovals from "../pages/purchase-requests/PurchaseRequestApprovals";
import PurchaseRequestAnalytics from "../pages/purchase-requests/PurchaseRequestAnalytics";
import TrackPurchaseRequests from "../pages/purchase-requests/TrackPurchaseRequests";

import ViewBudgetList from "../pages/budget/ViewBudget";
import TrackBudget from "../pages/budget/TraclBudget";
import BudgetApprovals from "../pages/budget/BudgetApprovals";
import BudgetAnalytics from "../pages/budget/BudgetAnalytics";

import RFQCreation from "../pages/quotations/RFQCreations";
import RFQAnalytics from "../pages/quotations/RFQAnalytics";
import RFQApprovals from "../pages/quotations/RFQApprovals";
import RFQComparison from "../pages/quotations/RFQComparison";
import RFQConversion from "../pages/quotations/RFQConversion";
import RFQSupplierResponses from "../pages/quotations/RFQSupplierResponses";
import RFQView from "../pages/quotations/RFQView";
import RFQTracking from "../pages/quotations/RFQTracking";
import RFQFullView from "../pages/quotations/quotationView/RFQFullView";

import FACreations from "../pages/forward-auction/FACreations";
import FAApprovals from "../pages/forward-auction/FAApprovals";
import FAComparison from "../pages/forward-auction/FAComparison";
import FASupplierResponses from "../pages/forward-auction/FASupplierResponses";
import FAView from "../pages/forward-auction/FAView";
import FAHistory from "../pages/forward-auction/FAHistory";
import FAAnalytics from "../pages/forward-auction/FAAnalytics";

import RACreations from "../pages/reverse-auction/RACreation";
import RAApprovals from "../pages/reverse-auction/RAApprovals";
import RASupplierResponses from "../pages/reverse-auction/RASupplierResponses";
import RAComparison from "../pages/reverse-auction/RAComparison";
import RAConversion from "../pages/reverse-auction/RAConversion";
import RAView from "../pages/reverse-auction/RAView";
import RAHistory from "../pages/reverse-auction/RAHistory";
import RAAnalytics from "../pages/reverse-auction/RAAnalytics";

import OrgSetup from "../pages/masters/orgmaster/OrgSetup";
import CreateOrganisation from "../pages/masters/orgmaster/CreateOrganisation";
import SitesSetup from "../pages/masters/sites/SitesSetup";
import AddSites from "../pages/masters/sites/CreateSites";
import SitesViewDetails from "../pages/masters/sites/SitesViewDetails";
import Departments from "../pages/masters/departments/Departments";
import Roles from "../pages/masters/roles/Roles";
import ReportingHeirarchy from "../pages/masters/roles/ReportingHeirarchy";
import SupplierCategory from "../pages/masters/supplier/SupplierCategory";
import Suppliers from "../pages/masters/supplier/Suppliers";
import Contractors from "../pages/masters/contractors/Contractors";
import ViewSuppliersDetails from "../pages/masters/supplier/ViewSuppliersDetails";
import AddContractors from "../pages/masters/contractors/AddContractors";
import ViewContractorDetails from "../pages/masters/contractors/ViewContractorDetails";
import ApprovalHeirarchy from "../pages/masters/contractors/ApprovalHeirarchy";
import MaterialCategory from "../pages/masters/materials/MaterialCategory";
import Materials from "../pages/masters/materials/Material";

import CostCenter from "../pages/masters/cost-center/CostCenter";
import GeneralLedger from "../pages/masters/general-ledger/GeneralLedger";
import Consultants from "../pages/masters/consultants/Consultants";
import Users from "../pages/masters/Users/Users";
import CreateUsers from "../pages/masters/Users/CreateUsers";
import AddSupplier from "../pages/masters/supplier/AddSupplier";
import AddConsultants from "../pages/masters/consultants/AddConsultants";
import AddMaterial from "../pages/masters/materials/AddMaterial";
import ViewMaterialDetails from "../pages/masters/materials/ViewMaterialDetails";
import ViewConsultantDetails from "../pages/masters/consultants/ViewConsultantDetails";
import CreateRole from "../pages/masters/roles/CreateRole";
import UserViewDetails from "../pages/masters/Users/ViewUser";
import RoleViewDetails from "../pages/masters/roles/ViewRole";
import ViewOrgPage from "../pages/masters/orgmaster/ViewOrg";
import SetPassword from "../pages/auth/SetPassword";
import SuccessMain from "../pages/auth/SuccessMain";
import EmailError from "../pages/auth/EmailError";
import ResetPassword from "../pages/auth/ResetPassword";
import OrgLegalInformation from "../pages/masters/orgmaster/OrgLegalInformation";
import OrgContactInformation from "../pages/masters/orgmaster/orgContactInformation";
import OrgBankInformation from "../pages/masters/orgmaster/OrgBankInformation";

import PurchaseOrderMain from "../pages/purchase-orders/PurchaseOrderMain";
import DeliveryAddress from "../pages/masters/delivery-address/DeliveryAddress";
import ViewPurchaseOrdersList from "../pages/purchase-orders/ViewPurchaseOrder";
import TrackPurchaseOrder from "../pages/purchase-orders/TrackPurchaseOrder";
import PurchaseOrderAnalytics from "../pages/purchase-orders/PurchaseOrderAnalytics";
import PurchaseOrderAprrovals from "../pages/purchase-orders/PurchaseOrderApprovals";
import BudgetCreation from "../pages/budget/BudgetCreations";
import PendingApprovalBudget from "../pages/budget/PendingApprovalBudget";
import ViewOrderDetails from "../pages/purchase-orders/ViewOrderDetails";
import ViewInvoiceList from "../pages/invoice/ViewAllInvoices";
import ViewInvoiceDetails from "../pages/invoice/ViewInvoiceDetails";
import InvoiceApproval from "../pages/invoice/InvoiceApproval";
import TrackInvoice from "../pages/invoice/TrackInvoice";
import InvoiceAnalytics from "../pages/invoice/InvoiceAnalytics";
import QCApprovals from "../pages/quotations/QCApprovals";
import ReadyToAward from "../pages/quotations/ReadyToAward";
import ViewDetailsEdit from "../pages/quotations/ViewDetailsEdit";
import ViewAllDispatch from "../pages/dispatch/ViewAllDispatch";
import ShowDispatchDetailsWrapper from "../pages/dispatch/showDispatchDetailsWrapper";
import ViewAllDeliveredGRN from "../pages/dispatch/ViewAllDeliveredGRN";
import InvoicePayables from "../pages/payments/invoicePayables";
import PendingPaymentApprovals from "../pages/payments/pendingPaymentApprovals";
import RunPayments from "../pages/payments/runPayments";
import ViewPayments from "../pages/payments/viewPayments";
import Main from "../pages/credit-debit-note/Main";
import ViewAllCN from "../pages/credit-debit-note/ViewAllCN";
import ViewPendingApprovalCN from "../pages/credit-debit-note/ViewPendingApprovalCN";
import NewPurchaseRequestMain from "../pages/purchase-requests/NewPRMain";
import ViewPRDetails from "../pages/purchase-requests/ViewPRDetails";
import TrackPRList from "../pages/purchase-requests/TrackPRList";
import PurchaseOrderApprovalsWrapper from "../pages/purchase-orders/PurchaseOrderApprovalsWrapper";
import PurchaseOrderViewWrapper from "../pages/purchase-orders/PurchaseOrderViewWrapper";
import { useDispatch } from "react-redux";
import { fetchCurrentUser } from "../store/slices/misc/currentUser";

import PaymentsAnalytics from "../pages/payments/PaymentsAnalytics";
import SequenceList from "../pages/masters/sequence/SequenceList";
import { useSelector } from "react-redux";
import { updateAccessibility } from "../store/slices/misc/accessibility";
import { updateMenuData } from "../store/slices/misc/menuData";
import { Flex, Spin } from "antd";
import { updateAllowedRoutes } from "../store/slices/misc/allowedRoutes";
import PoInvoiceDetails from "../pages/purchase-orders/OrderToInvoiceCreation";
import ViewMaterialInvoices from "../pages/invoice/ViewMaterialInvoices";
import { fetchSequenceAccess } from "../store/slices/misc/sequenceAccess";
import ViewBudgetDetails from "../pages/budget/ViewBudgetDetails";
import Cookies from "js-cookie";
import QCList from "../pages/quotations/QCList";
const globalRoutes = [
  "/",
  "/auth-reset",
  "/auth-success",
  "/auth-register",
  "/setPassword",
  "/success",
  "/errorEmail",
];
const routesMap = {
  dashboard: <Invest />,
  organisation: <ViewOrgPage />,
  orgLegalInformation: <OrgLegalInformation />,
  orgContactInformation: <OrgContactInformation />,
  orgBankInformation: <OrgBankInformation />,
  sites: <SitesSetup />,
  createSites: <AddSites />,

  "delivery-address": <DeliveryAddress />,
  sequence: <SequenceList />,
  "sitesViewDetails/:siteId": <SitesViewDetails />,
  departments: <Departments />,
  roles: <Roles />,
  "ViewRoleDetails/:roleID": <RoleViewDetails />,
  "reporting-hierarchy": <ReportingHeirarchy />,
  "supplier-category": <SupplierCategory />,
  suppliers: <Suppliers />,
  addSuppliers: <AddSupplier />,
  "vewSuppliersDetails/:siteId": <ViewSuppliersDetails />,
  "general-contractors": <Contractors />,
  "viewContractorDetails/:siteId": <ViewContractorDetails />,
  addContractors: <AddContractors />,
  addConsultants: <AddConsultants />,
  "viewConsultantDetails/:consultantId": <ViewConsultantDetails />,
  addMaterials: <AddMaterial />,
  "vewMaterialDetails/:materialId": <ViewMaterialDetails />,
  consultants: <Consultants />,
  users: <Users />,
  createUsers: <CreateUsers />,
  "userProfile/:userId": <UserViewDetails />,
  createRole: <CreateRole />,
  "approval-heirarchy": <ApprovalHeirarchy />,
  "materail-category": <MaterialCategory />,
  materails: <Materials />,
  "cost-center": <CostCenter />,
  "general-ledger": <GeneralLedger />,
  "add-new-purchase-request": <NewPurchaseRequestMain />,
  "approvals-purchase-request": <PurchaseRequestAprrovals />,
  "view-purchase-requests": <ViewPurchaseRequests />,
  "track-purchase-requests/:prId": <TrackPurchaseRequests />,
  "analytics-purchase-requests": <PurchaseRequestAnalytics />,
  "view_pr/:prId": <ViewPRDetails />,
  "track-list": <TrackPRList />,
  "new-budget": <BudgetCreation />,
  "budget-approvals": <BudgetApprovals />,
  "view-budget": <ViewBudgetList />,
  "pendingApproval-budget": <PendingApprovalBudget />,
  "track-budget": <TrackBudget />,
  "analytics-budget": <BudgetAnalytics />,
  "view_budget_details/:budgetId": <ViewBudgetDetails />,
  "new-rfq": <RFQCreation />,
  "qc-list":<QCList />,
  "rfq-approvals": <RFQApprovals />,
  "qc-approvals": <QCApprovals />,
  "view-rfqs": <RFQView />,
  "track-rfqs": <RFQTracking />,
  "analytics-rfq": <RFQAnalytics />,
  "rfq-comparison": <RFQComparison />,
  "rfq-conversion": <RFQConversion />,
  "rfq-supplier-responses": <RFQSupplierResponses />,
  "rfq-full-view": <RFQFullView />,
  "ready-to-award": <ReadyToAward />,
  "view/:qid": <ViewDetailsEdit />,
  "purchase-order-new": <PurchaseOrderMain />,
  "approvals-purchase-order": <PurchaseOrderAprrovals />,
  "approvals-purchase-order/:orderNumber": <PurchaseOrderApprovalsWrapper />,
  "view-purchase-order": <ViewPurchaseOrdersList />,
  "view-purchase-order/:orderNumber": <PurchaseOrderViewWrapper />,
  "track-purchase-order": <TrackPurchaseOrder />,
  "analytics-purchase-order": <PurchaseOrderAnalytics />,
  "credit-debit-note-new": <Main />,
  "all-credit-debit-notes": <ViewAllCN />,
  "pending-approval-credit-debit-notes": <ViewPendingApprovalCN />,
  "invoice-payables": <InvoicePayables />,
  "add-purchase-invoice/:orderNumber": <PoInvoiceDetails />,
  "pending-payment-approvals": <PendingPaymentApprovals />,
  "run-payments": <RunPayments />,
  "view-payments": <ViewPayments />,
  "payment-analytics": <PaymentsAnalytics />,
  "view-material-invoices/:orderNumber/:lineNo": <ViewMaterialInvoices />,
  "view-invoice-list": <ViewInvoiceList />,
  "view-invoice/:id": <ViewInvoiceDetails />,
  "approvals-purchase-invoice": <InvoiceApproval />,
  "track-purchase-invoice": <TrackInvoice />,
  "analytics-purchase-invoice": <InvoiceAnalytics />,
  "view-dispatch-list": <ViewAllDispatch />,
  "delivered-dispatch-list": <ViewAllDeliveredGRN />,
  "view-dispatch-details/:id": <ShowDispatchDetailsWrapper />,
  "payments-analytics": <PaymentsAnalytics />,
  "pages/terms-policy": <Terms />,
  "pages/faq": <Faq />,
  "pages/regular-v1": <Regularv1 />,
  "pages/regular-v2": <Regularv2 />,
  // "auth-success": <Success />,
  // "auth-reset": <ResetPassword />,
  // "auth-register": <Register />,
  // setPassword: <SetPassword />,
  // success: <SuccessMain />,
  // errorEmail: <EmailError />,
  // "errors/404-modern": <Error404Modern />,
  // "errors/404-classic": <Error404Classic />,
  // "errors/504-modern": <Error504Modern />,
  // "errors/504-classic": <Error504Classic />,
  // "invoice-print/:invoiceId": <InvoicePrint />,
  "*": <Error404Modern />,
};
const menu = [
  {
    text: "Dashboard",
    link: "dashboard",
  },
  {
    text: "Masters",
    subMenu: [
      {
        text: "Organisation",
        link: "organisation",
      },
      {
        text: "Sites",
        link: "sites",
      },
      {
        text: "Series",
        link: "sequence",
      },
      {
        text: "Delivery Address",
        link: "delivery-address",
      },
      {
        text: "Departments",
        link: "departments",
      },
      {
        text: "Roles",
        active: false,
        subMenu: [
          {
            text: "Roles",
            link: "roles",
          },
          {
            text: "Orgination Structure",
            link: "reporting-hierarchy",
          },
        ],
      },
      {
        text: "Users",
        link: "users",
      },
      {
        text: "Suppliers",
        active: false,
        subMenu: [
          {
            text: "Material Category",
            link: "supplier-category",
          },
          {
            text: "Suppliers",
            link: "suppliers",
          },
        ],
      },
      {
        text: "General Contractors",
        link: "general-contractors",
      },
      {
        text: "Approval Heirarchy",
        link: "approval-heirarchy",
      },
      {
        text: "Materials",
        link: "materails",
      },
      {
        text: "Cost Center",
        link: "cost-center",
      },
      {
        text: "General Ledger",
        link: "general-ledger",
      },
    ],
  },
  {
    text: "Procure to Pay",
    subMenu: [
      {
        text: "Budget",
        link: "analytics-budget",
        alt: ["view-budget"],
      },
      {
        text: "Purchase Request",
        link: "view-purchase-requests",
        alt: ["view-purchase-requests"],
      },
      {
        text: "Purchase Orders",
        link: "view-purchase-order",
        alt: ["view-purchase-order"],
      },
      {
        text: "Invoices",
        link: "view-invoice-list",
        alt: ["view-invoice-list"],
      },
      {
        text: "Goods Receipt Note",
        link: "view-dispatch-list",
        alt: ["view-dispatch-list"],
      },
      {
        text: "Credit Note",
        link: "all-credit-debit-notes",
        alt: ["all-credit-debit-notes"],
      },
      {
        text: "Payments",
        link: "payment-analytics",
        alt: ["view-payments"],
      },
    ],
  },
  {
    text: "Quotations",
    link: "analytics-rfq",
    // subMenu: [
    //   {
    //     text: "Quotations",
    //   },
    // ],
  },
];
const Pages = () => {
  const {
    data: currentUser,
    isLoading,
  } = useSelector((state) => state.currentUser);
  const [accessibility, setAccessibility] = useState([]);
  const [restrictedRoutes, setRestrictedRoutes] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const isAuthenticated = Cookies.get("access-token");
    if (
      !currentUser &&
      isAuthenticated &&
      !globalRoutes?.includes(window.location.pathname)
    ) {
      dispatch(fetchCurrentUser());
    }
  }, [dispatch]);

  useEffect(() => {
    if (currentUser?.userRoutePermissions) {
      try {
        dispatch(fetchSequenceAccess());
        const { userRoutePermissions } = currentUser;
        const decodedString = atob(userRoutePermissions);
        const decodedData = JSON.parse(decodedString);
        console.log("decodedData", decodedData);
        dispatch(updateAccessibility(decodedData));
        const _allowedRoutes = [];
        const _restrictedRoutes = [];
        Object.keys(decodedData).forEach((mod) => {
          const module = decodedData[mod];
          if (module?.access) {
            module?.routes?.forEach((route) => {
              if (route?.permission) {
                _allowedRoutes.push(route?.path);
              } else {
                _restrictedRoutes.push(route?.path);
              }
            });
          }
        });
        setAccessibility(_allowedRoutes);
        setRestrictedRoutes(_restrictedRoutes);
        const _menu = [];
        menu?.forEach((item) => {
          if (item?.subMenu?.length > 0) {
            const subMenu = [];
            item?.subMenu?.forEach((subItem) => {
              if (subItem?.subMenu?.length > 0) {
                const _subMenuChildren = [];
                subItem?.subMenu?.forEach((subSubItem) => {
                  if (_allowedRoutes.includes(subSubItem?.link)) {
                    _subMenuChildren.push({
                      text: subSubItem?.text,
                      link: subSubItem?.link,
                    });
                  }
                });
                if (_subMenuChildren.length > 0) {
                  subMenu.push({
                    text: subItem?.text,
                    active: false,
                    subMenu: _subMenuChildren,
                  });
                }
                return;
              }
              if (_allowedRoutes.includes(subItem?.link)) {
                subMenu.push({
                  text: subItem?.text,
                  link: subItem?.link,
                });
              } else {
                if (subItem?.alt?.length > 0) {
                  const altLink = subItem?.alt?.find((link) =>
                    _allowedRoutes.includes(link)
                  );
                  if (altLink) {
                    subMenu.push({
                      text: subItem?.text,
                      link: altLink,
                    });
                  }
                }
              }
            });
            if (subMenu.length > 0) {
              _menu.push({
                text: item?.text,
                subMenu: subMenu,
              });
            }
          } else {
            if (_allowedRoutes.includes(item?.link)) {
              _menu.push({
                text: item?.text,
                link: item?.link,
              });
            }
          }
        });
        dispatch(updateMenuData(_menu));
        dispatch(updateAllowedRoutes(_allowedRoutes));
      } catch (error) {
        console.log("error", error);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    const isAuthenticated = Cookies.get("access-token");
    if (!isAuthenticated && !globalRoutes?.includes(window.location.pathname)) {
      window.location.href = "/";
    }
  }, []);

  return (
    <Routes>
      <Route path={`${process.env.PUBLIC_URL}`} element={<Layout />}>
        {accessibility?.map(
          (path) =>
            routesMap[path] && (
              <Route key={path} path={path} element={routesMap[path]} />
            )
        )}
        {restrictedRoutes?.map((path) => (
          <Route path={path} element={<div> Unauthorized Access</div>} />
        ))}

        <Route
          path="user-profile-regular"
          element={<UserProfileRegular />}
        ></Route>
        <Route
          path="user-profile-notification"
          element={<UserProfileNotification />}
        ></Route>
        <Route
          path="user-profile-activity"
          element={<UserProfileActivity />}
        ></Route>
        <Route
          path="user-profile-setting"
          element={<UserProfileSetting />}
        ></Route>
      </Route>

      <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
        <Route path="auth-success" element={<Success />}></Route>
        <Route path="auth-reset" element={<ResetPassword />}></Route>
        <Route path="auth-register" element={<Register />}></Route>
        <Route index element={<Login />}></Route>
        <Route path="setPassword" element={<SetPassword />}></Route>
        <Route path="success" element={<SuccessMain />}></Route>
        <Route path="errorEmail" element={<EmailError />}></Route>

        {/* <Route path="errors">
          <Route path="404-modern" element={<Error404Modern />}></Route>
          <Route path="404-classic" element={<Error404Classic />}></Route>
          <Route path="504-modern" element={<Error504Modern />}></Route>
          <Route path="504-classic" element={<Error504Classic />}></Route>
        </Route> */}
        <Route
          path="*"
          element={
            accessibility.length === 0 ? (
              <>
                <Flex align="center" gap="middle" className="pageLoader">
                  <Spin size="large" className="m-auto" />
                </Flex>
              </>
            ) : (
              <Error404Modern />
            )
          }
        ></Route>
      </Route>
    </Routes>
  );
};
export default Pages;
